import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { historyTable } from "../../common/Columns";
import HistoryTable from "../../components/datatable/HistoryTable";
import {
  deleteHistory,
  fetchHistory,
  fetchTables,
  fetchTotalHours,
} from "../../services/Tables";
import { format, parseISO } from "date-fns"; // Import date-fns functions
import Select from "../../UI/Select";

const PoolHistory = () => {
  const [history, setHistory] = useState([]);
  const [totalHours, setTotalHours] = useState<string>("00:00:00");
  const [isLoading, setIsLoading] = useState(false);
  const [pageData, setPageData] = useState({});
  const [pagination, setPagination] = useState(1);
  const [tables, setTables] = useState([]);
  const [filterTable, setFilterTable] = useState("0");
  const [filterStartDate, setFilterStartDate] = useState(
    new Date(Date.now() - 86400000)
  );
  const [sortBy, setSortBy] = useState("created_at-desc");
  const [filterEndDate, setFilterEndDate] = useState(new Date());

  const getTables = async () => {
    try {
      const res = await fetchTables();

      if (res.error) throw new Error(res.error.message);
      setTables(res.data.table);
    } catch (error: any) {
      toast.error(error?.message);
    }
  };
  const getHistory = async () => {
    const filters = {
      table: filterTable,
      startDate: filterStartDate,
      endDate: filterEndDate,
      sortBy: sortBy,
    };
    setIsLoading(true);
    try {
      const res = await fetchHistory(pagination, filters);
      const responseData = res.data;

      if (res.error) throw new Error(res.error.message);
      setHistory(responseData.stopwatch);
      delete responseData.stopwatch;
      setPageData(responseData);
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getTotalHours = async () => {
    const filters = {
      table: filterTable,
      startDate: filterStartDate,
      endDate: filterEndDate,
    };
    try {
      const res = await fetchTotalHours(filters);

      if (res.error) throw new Error(res.error.message);
      setTotalHours(res.data.totalHours);
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const handleDateChange = (dateString, setDateFunction) => {
    const parsedDate = parseISO(dateString);
    setDateFunction(parsedDate);
  };

  useEffect(() => {
    getHistory();
  }, [pagination]);

  useEffect(() => {
    getHistory();
    getTables();
    getTotalHours();
  }, []);

  const handleDelete = async (id: number) => {
    const confirmed = window.confirm(
      "Are you sure? You cannot undo this action."
    );

    if (confirmed) {
      try {
        await deleteHistory(id);
        getHistory();
        toast.success("Deleted successfully");
      } catch (error) {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <>
      <div className="m-4 flex items-center gap-2">
        Total: <p className="text-xl font-semibold">{totalHours}</p>
      </div>
      <div className="p-4 sm:w-auto sm:mx-8 mt-8 rounded-xl shadow-lg dark:bg-neutral-800 bg-white w-[90vw] mx-auto">
        <div className="flex flex-col sm:flex-row sm:flex-wrap lg:flex-nowrap gap-4">
          <label className="w-full">
            <p className="label-text dark:text-neutral-100 pl-1 pb-1">From</p>
            <input
              type="datetime-local"
              value={format(filterStartDate, "yyyy-MM-dd'T'HH:mm")}
              onChange={(e) => {
                if (!e.target.value) return;
                handleDateChange(e.target.value, setFilterStartDate);
              }}
              className="w-full dark:bg-neutral-900 px-4 py-2 rounded-lg font-sans shadow"
            />
          </label>
          <label className="w-full">
            <p className="label-text dark:text-neutral-100 pl-1 pb-1">To</p>
            <input
              value={format(filterEndDate, "yyyy-MM-dd'T'HH:mm")}
              type="datetime-local"
              max={format(new Date(), "yyyy-MM-dd'T'HH:mm")}
              onChange={(e) => {
                if (!e.target.value) return;
                handleDateChange(e.target.value, setFilterEndDate);
              }}
              className="w-full dark:bg-neutral-900 px-4 py-2 rounded-lg font-sans shadow"
            />
          </label>
          <label className="w-full">
            <p className="label-text dark:text-neutral-100 pl-1 pb-1">Table</p>
            <Select
              id="table"
              value={filterTable}
              onChange={(e: any) => setFilterTable(e.target.value)}
              className="w-full dark:bg-neutral-900 px-4 py-2.5 rounded-lg"
            >
              <option value="0">All</option>
              {tables.map((e: any) => (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              ))}
            </Select>
          </label>
        </div>
        <label className="w-full">
          <p className="mt-4 label-text dark:text-neutral-100 pl-1 pb-1">
            Sort by
          </p>
          <Select
            id="sort"
            value={sortBy}
            onChange={(e: any) => setSortBy(e.target.value)}
            className="w-full dark:bg-neutral-900 px-4 py-2.5 rounded-lg"
          >
            <option value="created_at-desc">Date (new to old)</option>
            <option value="created_at-asc">Date (old to new)</option>
            <option value="hours-asc">Time played (low to high)</option>
            <option value="hours-desc">Time played (high to low)</option>
          </Select>
        </label>
        <div className="flex items-center gap-2">
          <button
            onClick={() => {
              getHistory();
              getTotalHours();
            }}
            className="bg-success rounded-lg px-8 font-medium text-lg py-1.5 mt-4"
          >
            Filter
          </button>
          <button
            onClick={() => {
              setFilterTable("0");
              setFilterStartDate(new Date(Date.now() - 86400000));
              setFilterEndDate(new Date());
              getHistory();
            }}
            className="bg-neutral-300 dark:text-white text-black dark:bg-neutral-900 rounded-lg px-8 font-medium text-lg py-1.5 mt-4"
          >
            Reset
          </button>
        </div>
      </div>
      <HistoryTable
        handleDelete={handleDelete}
        data={history}
        columns={historyTable}
        pageData={pageData}
        setPagination={setPagination}
        isLoading={isLoading}
      />
    </>
  );
};

export default PoolHistory;
