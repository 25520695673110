// useTimer.js
import { useState, useEffect, useRef } from "react";

const useTimer = (active = false, initialTime = { hours: 0, minutes: 0, seconds: 0 }) => {
  const [isActive, setIsActive] = useState(active);
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    setTime({
      hours: initialTime.hours || 0,
      minutes: initialTime.minutes || 0,
      seconds: initialTime.seconds || 0,
    });
  }, [initialTime.seconds, initialTime.minutes, initialTime.hours]);

  const intervalRef = useRef(null);

  const startTimer = () => {
    setIsActive(true);
  };

  const setTimer = (time:{ hours: number, minutes: number, seconds: number}) => {
    setTime(time);
  }

  const pauseTimer = () => {
    setIsActive(false);
  };

  const resetTimer = () => {
    setIsActive(false);
    setTime({hours:0, minutes:0, seconds:0});
  };

  useEffect(() => {
    let interval;

    if (isActive) {
      interval = setInterval(() => {
        setTime((prevTime) => {
          const nextTime = { ...prevTime };

          if (nextTime.seconds + 1 > 59) {
            nextTime.minutes += 1;
            nextTime.seconds = 0;

            if (nextTime.minutes > 59) {
              nextTime.hours += 1;
              nextTime.minutes = 0;
            }
          } else {
            nextTime.seconds += 1;
          }

          return nextTime;
        });
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = interval;

    return () => clearInterval(interval);
  }, [isActive]);

  return {
    isActive,
    time,
    startTimer,
    setTimer,
    pauseTimer,
    resetTimer,
  };
};

export default useTimer;
