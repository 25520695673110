import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ChevronIcon, EditIcon, GarbageIcon } from "../../assets/icons";
import AddModal from "../../components/modals/AddModal";
import EditModal from "../../components/modals/EditModal";
import {
  addCategory,
  editCategory,
  getCategories,
  removeCategory,
} from "../../services/Category";

const Menu = () => {
  const [categories, setCategories]: any = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [addModal, setAddModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [editId, setEditId] = useState(0);
  const [editName, setEditName] = useState("");
  const [editOrder, setEditOrder] = useState("");
  const [image, setImage] = useState("");
  const [editActive, setEditActive] = useState(true);

  async function getCategoriess() {
    try {
      const response = await getCategories();
      setCategories(response?.data?.category);
    } catch (error: any) {
      toast.error(error?.message);
    }
  }
  useEffect(() => {
    getCategoriess();
  }, []);

  const confirmDelete = async (id: number) => {
    const confirmed = window.confirm("Are you sure?");

    if (confirmed) {
      try {
        const response = await removeCategory(id);
        setCategories((prev: any) => prev.filter((e: any) => e.id !== id));
        toast.info("Category removed");
      } catch (error: any) {
        toast.error(error?.message);
      }
    }
  };

  const editModalHandler = async (id: number) => {
    setShowModal(true);
    const category = categories.filter((e: any) => e.id === id);
    setEditName(category[0].name);
    setEditActive(category[0].active);
    setEditOrder(category[0].menu_order);
    setImage(category[0].image_url);
    setEditId(id);
  };

  const submitHandler = async (e: any) => {
    e.preventDefault();
    const payload: any = new FormData();
    e.target.editImage.files[0] &&
      payload.append("image", e.target.editImage.files[0]);
    payload.append("name", editName);
    payload.append("active", editActive);
    payload.append("menu_order", editOrder);
    try {
      setIsLoading(true);
      await editCategory(editId, payload);

      getCategoriess();
      toast.success("Category edited!");
      setShowModal(false);
    } catch (err: any) {
      toast.error(err?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const addSubmitHandler = async (e: any) => {
    e.preventDefault();
    const payload: any = new FormData();
    setIsLoading(true);

    e.target.image.files[0] && payload.append("image", e.target.image.files[0]);
    payload.append("name", e.target.editName.value);
    payload.append("active", e.target.editActive.checked ? 1 : 0);
    payload.append("menu_order", e.target.editOrder.value);
    try {
      await addCategory(payload);

      toast.success("Category added!");
      getCategoriess();
      setAddModal(false);
    } catch (err: any) {
      toast.error(err?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="text-sm breadcrumbs px-6">
        <ul>
          <li>
            <a>Menu</a>
          </li>
          <li>
            <a>Categories</a>
          </li>
        </ul>
      </div>

      <button
        onClick={() => setAddModal(true)}
        className="btn btn-success mx-4 my-4 shadow text-white"
      >
        Add new
      </button>

      <ul className="bg-white dark:bg-neutral-800 mx-4 rounded-lg mb-24 overflow-auo ">
        {categories.map((e: any) => {
          return (
            <li
              className="flex justify-between items-center bg-white dark:bg-neutral-800 min-w-[700px] px-4 rounded cursor-pointer border-b dark:border-b-neutral-700"
              key={e.id}
            >
              <div className="flex items-center gap-4 w-full">
                <p className="w-4">{e.id}</p>
                <div className="w-[150px] h-[80px] overflow-hidden flex">
                  <img
                    src={e.image_url}
                    alt={e.name}
                    className="w-full h-full object-cover object-center"
                  />
                </div>
                <h4>{e.name}</h4>
              </div>
              <div className="flex gap-4 items-center">
                <button
                  className="dark:text-white"
                  onClick={() => {
                    editModalHandler(e.id);
                  }}
                >
                  <EditIcon />
                </button>
                <button
                  onClick={() => {
                    confirmDelete(e.id);
                  }}
                >
                  <GarbageIcon />
                </button>
                <Link to={`/products/${e.id}`} className="group">
                  <ChevronIcon />
                </Link>
              </div>
            </li>
          );
        })}
      </ul>
      {showModal && (
        <EditModal
          title="Edit Category"
          closeModal={() => setShowModal(false)}
          submitHandler={submitHandler}
          isLoading={isLoading}
        >
          <div className="w-1/2 overflow-hidden">
            <img src={image} className="object-cover" alt="" />
          </div>
          <div className="form-control w-full -mt-2">
            <label className="label">
              <span className="label-text">Select image</span>
            </label>
            <input
              type="file"
              id="editImage"
              className="file-input w-full bg-neutral-100 dark:bg-neutral-700 dark:file-input-bordered"
            />
          </div>
          <div>
            <label htmlFor="edit-name" className="label-text">
              Name
            </label>
            <input
              value={editName}
              onChange={(e) => {
                setEditName(e.target.value);
              }}
              className="py-2 rounded-lg bg-neutral-100 focus:ring-2 dark:bg-neutral-700 ring-success outline-none w-full px-4"
              id="edit-name"
            />
          </div>
          <div>
            <label htmlFor="edit-name" className=" label-text">
              Menu order
            </label>
            <input
              value={editOrder}
              onChange={(e) => {
                setEditOrder(e.target.value);
              }}
              className="py-2 rounded-lg bg-neutral-100 focus:ring-2 dark:bg-neutral-700 ring-success outline-none w-full px-4"
              id="edit-order"
            />
          </div>
          <div className="form-control w-max">
            <label className="label cursor-pointer">
              <input
                type="checkbox"
                checked={editActive}
                onChange={() => {
                  setEditActive((e) => !e);
                }}
                className="checkbox checkbox-success checkbox-sm mr-2"
              />
              <span className="label-text">Set Active</span>
            </label>
          </div>
        </EditModal>
      )}
      {addModal && (
        <AddModal
          title="Add Category"
          closeModal={() => setAddModal(false)}
          submitHandler={addSubmitHandler}
          isLoading={isLoading}
        >
          <div className="form-control w-full -mt-2">
            <label className="label">
              <span className="label-text">Select image</span>
            </label>
            <input
              type="file"
              id="image"
              className="file-input w-full bg-neutral-100 dark:bg-neutral-700 dark:file-input-bordered"
            />
          </div>
          <div>
            <label htmlFor="editName" className="label-text">
              Name
            </label>
            <input
              className="py-2 rounded-lg bg-neutral-100 dark:bg-neutral-700 focus:ring-2 ring-success outline-none w-full px-4"
              id="editName"
            />
          </div>
          <div>
            <label htmlFor="editName" className=" label-text">
              Menu order
            </label>
            <input
              value={editOrder}
              onChange={(e) => {
                setEditOrder(e.target.value);
              }}
              className="py-2 rounded-lg bg-neutral-100 focus:ring-2 dark:bg-neutral-700 ring-success outline-none w-full px-4"
              id="editOrder"
            />
          </div>
          <div className="form-control w-max">
            <label className="label cursor-pointer">
              <input
                type="checkbox"
                checked={editActive}
                onChange={() => {
                  setEditActive((e) => !e);
                }}
                className="checkbox checkbox-success checkbox-sm mr-2"
                id="editActive"
              />
              <span className="label-text">Set Active</span>
            </label>
          </div>
        </AddModal>
      )}
    </>
  );
};

export default Menu;
