import { Link, useOutletContext } from "react-router-dom";
import { ChevronIcon } from "../../assets/icons";

const Table = ({ data, columns }: any) => {
  const user: any = useOutletContext();
  return (
    <div className="overflow-x-auto mx-auto w-[90vw] sm:w-auto bg-white dark:bg-neutral-800 rounded-xl mt-8 mb-24">
      <table className="table">
        {/* head */}
        <thead>
          <tr className="dark:border-b-neutral-700">
            <th></th>
            {columns.map((e: any, index: any) => {
              return (
                <th key={index} className="dark:text-neutral-100">
                  {e}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((e: any, index: number) => {
            return (
              <tr
                className="hover:bg-neutral-100 dark:hover:bg-neutral-900 border-b-neutral-200 dark:border-b-neutral-700"
                key={e.id}
              >
                <th>{index + 1}</th>
                <td>{e?.memberships[0]?.type.name || "Нема"}</td>
                <td>{e.first_name + " " + e.last_name}</td>
                <td>{e.phone}</td>
                <td className="">
                  {e.membership_active == 1 ? (
                    <p className="bg-success py-0.5 text-sm px-2 text-white font-medium rounded-2xl w-max">
                      {e.memberships[0]?.status || "not active"}
                    </p>
                  ) : (
                    <p className="bg-danger py-0.5  text-sm px-2 text-white font-medium rounded-2xl w-max">
                      {e.memberships[0]?.status || "not active"}
                    </p>
                  )}
                </td>
                <td>{e.memberships[0]?.active_from || "not active"}</td>
                <td>{e.memberships[0]?.active_to || "not active"}</td>
                <td>
                  <Link
                    to={
                      user.user_roles.role_name === "ADMIN"
                        ? `/members/${e.id}`
                        : `/members/${e.id}`
                    }
                  >
                    <ChevronIcon />
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
