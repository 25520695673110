import React, { useState, useEffect, FC } from "react";
import { InformationIcon, Logo, RefreshIcon } from "../../assets/icons";
import { fetchAvailability } from "../../services/Tables";
import { PoolTableAvailability } from "../../utilities/types";
import { toast } from "react-toastify";

const PoolTableComponent: FC<PoolTableAvailability> = ({
  name,
  status,
  color,
}) => {
  return (
    <div
      className={`border-2 rounded-xl p-4 mb-4 `}
      style={{ borderColor: color }}
    >
      <div className="flex justify-between">
        <span>{name}</span>
        <span>{status}</span>
      </div>
    </div>
  );
};

const InfoIcon = () => (
  <div className="flex items-start sm:items-center mb-4 text-neutral-400 gap-2 mt-4">
    <div>
      <InformationIcon />
    </div>
    <span className="underline italic text-sm">
      Достапноста е прикажана во моментот и може да се промени. Ние не сме
      одговорни ако масата биде зафатена пред вашето пристигнување.
    </span>
  </div>
);

const Billiards = () => {
  const [poolTableData, setPoolTableData] = useState<
    Array<PoolTableAvailability>
  >([]);
  const [lastRefreshDate, setLastRefreshDate] = useState<string>(""); // State to keep track of the last refresh date

  const fetchData = async () => {
    try {
      const data = await fetchAvailability();
      setPoolTableData(data?.data);
      setLastRefreshDate(new Date().toLocaleString()); // Update the last refresh date
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="p-4 flex flex-col min-h-screen">
      <div className="flex items-center mb-4">
        {/* Logo Component */}
        {/* Replace 'LogoComponent' with your actual logo component */}
        <Logo />

        <span className="flex items-center ml-4">Достапност</span>
      </div>

      {/* Refresh Button and Horizontal Line */}
      <div className="flex flex-col items- mb-4">
        <div className="flex justify-between items-center px-2">
          {" "}
          <p className="text-sm mt-2">{lastRefreshDate}</p>{" "}
          {/* Display last refresh date */}
          <button
            onClick={() => fetchData()}
            className="border-none bg-none flex gap-2 text-sm items-center"
          >
            рефреширај <RefreshIcon />
          </button>
        </div>
        <div className="h-px bg-neutral-700 w-full mt-2"></div>
      </div>

      {/* List of Pool Table Components */}
      {poolTableData.map((table) => (
        <PoolTableComponent
          key={table.table_id}
          name={table.name}
          status={table.status}
          color={table.color}
        />
      ))}

      {/* Info Icon Component */}
      <InfoIcon />

      {/* Copyright Section */}
      <div className="mt-auto text-center text-xs text-neutral-500">
        <p>&copy; 2024 All rights reserved</p>
      </div>
    </div>
  );
};

export default Billiards;
