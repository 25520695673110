import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { allGamesTable } from "../../common/Columns";
import AllGamesTable from "../../components/datatable/AllGamesTable";
import { createGame, getActiveMembers, getGames } from "../../services/Members";

const Games = () => {
  const [members, setMembers] = useState([]);
  const [games, setGames] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const getMembers = async () => {
    try {
      const response = await getActiveMembers();

      setMembers(response.data.members);
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const getGame = async () => {
    try {
      const responseGames = await getGames();
      setGames(responseGames.data.game);
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const payload = {
      player_one_id: e.target.playerOne.value,
      player_two_id: e.target.playerTwo.value,
      game_length_one: e.target.timePlayedOne.value,
      game_length_two: e.target.timePlayedTwo.value,
    };
    try {
      setIsLoading(true);
      await createGame(payload);

      toast.success("Играта беше креирана!");
      getGame();
    } catch (err: any) {
      toast.error(err?.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMembers();
    getGame();
  }, []);


  return (
    <>
      <h1 className="font-semibold text-lg px-10 py-6">Запиши игра</h1>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-2 bg-white dark:bg-neutral-800 shadow-lg px-2 py-4 sm:mx-8 sm:w-auto w-[90vw] mx-auto rounded-xl"
      >
        <div className="flex flex-col px-4 w-full">
          <label htmlFor="" className="label-text mb-1 dark:text-white">
            Прв играч
          </label>
          <select
            name="playerOne"
            id="playerOne"
            className="px-4 py-2 rounded-lg w-full bg-neutral-100 text-sm appearance-none dark:bg-neutral-700"
          >
            <option value="">Избери прв играч</option>
            {members.map((e: any, index) => (
              <option value={e.id} key={index}>
                {e.first_name + " " + e.last_name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col px-4">
          <label htmlFor="" className="label-text mb-1 dark:text-white">
            Втор играч
          </label>
          <select
            name="playerTwo"
            id="playerTwo"
            className="px-4 py-2 rounded-lg w-full bg-neutral-100 text-sm appearance-none dark:bg-neutral-700"
          >
            <option value="">Избери втор играч</option>
            {members.map((e: any, index) => (
              <option value={e.id} key={index}>
                {e.first_name + " " + e.last_name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col px-4">
          <label htmlFor="" className="label-text mb-1 dark:text-white">
            Време на прв играч
          </label>
          <select
            name="timePlayedOne"
            id="timePlayedOne"
            className="px-4 py-2 rounded-lg w-full bg-neutral-100 text-sm appearance-none dark:bg-neutral-700"
          >
            <option value="">Избери време</option>
            <option value="0.5">30 мин</option>
            <option value="1">1 час</option>
            <option value="1.5">1 часа 30 мин</option>
            <option value="2">2 часа</option>
            <option value="2.5">2 часа 30 мин</option>
            <option value="3">3 часа</option>
            <option value="3.5">3 часа 30 мин</option>
            <option value="4">4 часа</option>
            <option value="4.5">4 часа 30 мин</option>
            <option value="5">5 часа</option>
            <option value="5.5">5 часа 30 мин</option>
            <option value="6">6 часа</option>
            <option value="6.5">6 часа 30 мин</option>
            <option value="7">7 часа</option>
            <option value="7.5">7 часа 30 мин</option>
            <option value="8">8 часа</option>
          </select>
        </div>
        <div className="flex flex-col px-4 dark:text-white">
          <label htmlFor="" className="label-text mb-1 dark:text-white">
            Време на втор играч
          </label>
          <select
            name="timePlayedTwo"
            id="timePlayedTwo"
            className="px-4 py-2 rounded-lg w-full bg-neutral-100 text-sm appearance-none dark:bg-neutral-700"
          >
            <option value="">Избери време</option>
            <option value="0.5">30 мин</option>
            <option value="1">1 час</option>
            <option value="1.5">1 часа 30 мин</option>
            <option value="2">2 часа</option>
            <option value="2.5">2 часа 30 мин</option>
            <option value="3">3 часа</option>
            <option value="3.5">3 часа 30 мин</option>
            <option value="4">4 часа</option>
            <option value="4.5">4 часа 30 мин</option>
            <option value="5">5 часа</option>
            <option value="5.5">5 часа 30 мин</option>
            <option value="6">6 часа</option>
            <option value="6.5">6 часа 30 мин</option>
            <option value="7">7 часа</option>
            <option value="7.5">7 часа 30 мин</option>
            <option value="8">8 часа</option>
          </select>
        </div>
        <button
          type="submit"
          disabled={isLoading}
          className="ml-austo smr-4 mx-4 mt-4 bg-success px-8 py-2 rounded-lg shadow text-white"
        >
          {isLoading ? (
            <span className="loading loading-ring loading-sm"></span>
          ) : (
            "Запиши"
          )}
        </button>
      </form>
      {/* <div className="mx-8 mt-8"> */}
      <AllGamesTable data={games} columns={allGamesTable} />
      {/* </div> */}
    </>
  );
};

export default Games;
