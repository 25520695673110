import { Link } from "react-router-dom";

const MemberCard = ({
  member,
  memberships,
  hours,
  updateMembership,
}: unknown) => {
  return (
    <div className="m-4 flex flex-col md:flex-wrap ">
      <div className="text-sm breadcrumbs px-4 mb-4">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/members">Members</Link>
          </li>
          <li>{member?.first_name + " " + member?.last_name}</li>
        </ul>
      </div>
      <div className="flex gap-4 bg-white dark:bg-neutral-800 w-full xl:w-1/3 rounded-lg p-8">
        <div className="rounded-xl overflow-hidden">
          <img
            src={`https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=https://admin.thefirst.mk/public/members/${member.id}`}
            alt="qrcode"
          />
          {/* <UserIcon /> */}
        </div>
        <div className="">
          <h3 className="text-xs tracking-wide uppercase text-neutral-400">
            { memberships && memberships[0]?.type.name}
          </h3>
          <h2 className="font-medium">
            {member?.first_name + " " + member?.last_name}
          </h2>
          <p className="text-sm text-neutral-700 dark:text-neutral-300">
            {member?.phone}
          </p>
          {member.membership_active == "1" ? (
            <p className="mt-2 bg-success text-sm py-0.5 px-2 text-white font-medium rounded-2xl w-max">
              {memberships[0]?.status}
            </p>
          ) : (
            <p className="mt-2 bg-danger py-0.5 text-sm px-2 text-white font-medium rounded-2xl w-max">
              {memberships[0]?.status || "not active"}
            </p>
          )}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 mt-4 bg-white dark:bg-neutral-800 shadow rounded-lg w-full xl:w-1/3 p-6">
        <div>
          <p className="text-sm font-medium">Member since:</p>
          <p className="text-sm text-neutral-500">
            {memberships[memberships.length - 1]?.active_from || "None"}
          </p>
        </div>
        <div>
          <p className="text-sm font-medium">Subscription active:</p>
          <p className="text-sm text-neutral-500">
            {memberships[0]?.active_to || "None"}
          </p>
        </div>
        <div>
          <p className="text-sm font-medium">Monthly hours:</p>
          <p
            className={`text-sm text-neutral-500 font-semibold ${
              parseInt(hours.sumHours) < memberships[0]?.type.min_hours
                ? "text-danger"
                : "text-success"
            }`}
          >
            {hours.sumHours || 0}
          </p>
        </div>
        <div>
          <p className="text-sm font-medium">Total hours:</p>
          <p className="text-sm text-neutral-500 font-semibold">
            {hours.totalHours || 0}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-2 mt-4 bg-white dark:bg-neutral-800 shadow rounded-lg w-full xl:w-1/3 p-4">
        {member?.membership_active == "0" && (
          <>
            <button
              onClick={updateMembership}
              className="text-white bg-green-600 px-4 py-2 rounded-lg transition-colors hover:bg-green-500"
            >
              Renew Membership
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default MemberCard;
