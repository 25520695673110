import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { historyOrders, historyTable } from "../../common/Columns";
import HistoryTable from "../../components/datatable/HistoryTable";
import { format, parseISO } from "date-fns"; // Import date-fns functions
import Select from "../../UI/Select";
import { getHistoryOrders, getTableOrders } from "../../services/Products";
import OrderHistoryTable from "./OrderHistoryTable";
import { getTables as getTabless } from "../../services/Products";

const OrderHistory = () => {
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageData, setPageData] = useState({});
  const [pagination, setPagination] = useState(1);
  const [tables, setTables] = useState([]);
  const [filterTable, setFilterTable] = useState("0");
  const [filterStartDate, setFilterStartDate] = useState(
    new Date(Date.now() - 86400000)
  );
  const [sortBy, setSortBy] = useState("created_at-desc");
  const [filterEndDate, setFilterEndDate] = useState(new Date());

  const getTables = async () => {
    try {
      const res = await getTabless();

      if (res.error) throw new Error(res.error.message);
      setTables(res.data);
    } catch (error: any) {
      toast.error(error?.message);
    }
  };
  const getHistory = async () => {
    const filters = {
      table: filterTable,
      startDate: filterStartDate,
      endDate: filterEndDate,
      sortBy: sortBy,
    };
    setIsLoading(true);
    try {
      const res = await getHistoryOrders(pagination, filters);
      const responseData = res.data;
      console.log(res);

      if (res.error) throw new Error(res.error.message);
      setHistory(responseData.data);
      setPageData(responseData);
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDateChange = (dateString, setDateFunction) => {
    const parsedDate = parseISO(dateString);
    const formattedDate = format(parsedDate, "yyyy-MM-dd'T'HH:mm");
    setDateFunction(formattedDate);
  };

  useEffect(() => {
    getHistory();
  }, [pagination]);

  useEffect(() => {
    getTables();
  }, []);

  const handleDelete = async (id: number) => {
    const confirmed = window.confirm(
      "Are you sure? You cannot undo this action."
    );

    if (confirmed) {
      try {
        toast.success("Deleted successfully");
      } catch (error) {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <>
      <div className="p-4 sm:w-auto sm:mx-8 mt-8 rounded-xl shadow-lg dark:bg-neutral-800 bg-white w-[90vw] mx-auto">
        <div className="flex flex-col sm:flex-row sm:flex-wrap lg:flex-nowrap gap-4">
          <label className="w-full">
            <p className="label-text dark:text-neutral-100 pl-1 pb-1">From</p>
            <input
              type="datetime-local"
              value={format(filterStartDate, "yyyy-MM-dd'T'HH:mm")}
              onChange={(e) => {
                if (!e.target.value) return;
                handleDateChange(e.target.value, setFilterStartDate);
              }}
              className="w-full dark:bg-neutral-900 px-4 py-2 rounded-lg font-sans shadow"
            />
          </label>
          <label className="w-full">
            <p className="label-text dark:text-neutral-100 pl-1 pb-1">To</p>
            <input
              value={format(filterEndDate, "yyyy-MM-dd'T'HH:mm")}
              type="datetime-local"
              max={format(new Date(), "yyyy-MM-dd'T'HH:mm")}
              onChange={(e) => {
                if (!e.target.value) return;
                handleDateChange(e.target.value, setFilterEndDate);
              }}
              className="w-full dark:bg-neutral-900 px-4 py-2 rounded-lg font-sans shadow"
            />
          </label>
          <label className="w-full">
            <p className="label-text dark:text-neutral-100 pl-1 pb-1">Table</p>
            <Select
              id="table"
              value={filterTable}
              onChange={(e: any) => setFilterTable(e.target.value)}
              className="w-full dark:bg-neutral-900 px-4 py-2.5 rounded-lg"
            >
              <option value="0">All</option>
              {tables.map((e: any) => (
                <option key={e.id} value={e.id}>
                  {e.id}
                </option>
              ))}
            </Select>
          </label>
        </div>
        <label className="w-full">
          <p className="mt-4 label-text dark:text-neutral-100 pl-1 pb-1">
            Sort by
          </p>
          <Select
            id="sort"
            value={sortBy}
            onChange={(e: any) => setSortBy(e.target.value)}
            className="w-full dark:bg-neutral-900 px-4 py-2.5 rounded-lg"
          >
            <option value="created_at-desc">Date (new to old)</option>
            <option value="created_at-asc">Date (old to new)</option>
            <option value="hours-asc">Time played (low to high)</option>
            <option value="hours-desc">Time played (high to low)</option>
          </Select>
        </label>
        <div className="flex items-center gap-2">
          <button
            onClick={() => {
              getHistory();
            }}
            className="bg-success rounded-lg px-8 font-medium text-lg py-1.5 mt-4"
          >
            Filter
          </button>
          <button
            onClick={() => {
              setFilterTable("0");
              setFilterStartDate(new Date(Date.now() - 86400000));
              setFilterEndDate(new Date());
              getHistory();
            }}
            className="bg-neutral-300 dark:text-white text-black dark:bg-neutral-900 rounded-lg px-8 font-medium text-lg py-1.5 mt-4"
          >
            Reset
          </button>
        </div>
      </div>
      <OrderHistoryTable
        handleDelete={handleDelete}
        data={history}
        columns={historyOrders}
        pageData={pageData}
        setPagination={setPagination}
        isLoading={isLoading}
      />
    </>
  );
};

export default OrderHistory;
