const AddModal = ({
  children,
  title,
  closeModal,
  submitHandler,
  isLoading,
}: any) => {
  return (
    <>
      <div
        id="backdrop"
        onClick={closeModal}
        tabIndex={-1}
        className="fixed w-screen h-screen inset-0 z-40 bg-black opacity-25"
      ></div>
      <form
        encType="multipart/form-data"
        onSubmit={submitHandler}
        className="flex flex-col md:w-2/3 w-11/12 left-1/2 top-1/2 max-w-xl -translate-y-1/2 rounded-xl shadow-lg -translate-x-1/2 bg-white dark:bg-neutral-900 z-50 absolute anim"
      >
        <div className="px-8 py-4 flex justify-between items-center">
          <h2 className="font-semibold">{title}</h2>
          <button
            type="button"
            onClick={closeModal}
            className="text-xs font-bold"
          >
            &times;
          </button>
        </div>
        <div className="px-8 py-4 flex flex-col gap-4">{children}</div>
        <div className="flex justify-end gap-2 rounded-b-xl dark:bg-neutral-800 bg-neutral-100 px-4 py-2">
          <button
            type="button"
            onClick={closeModal}
            className="py-2 px-4 bg-neutral-200 dark:bg-neutral-600 rounded"
          >
            Close
          </button>
          <button
            type="submit"
            disabled={isLoading}
            className="py-2 px-4 bg-success text-white rounded"
          >
            {isLoading ? (
              <span className="loading loading-ring loading-sm"></span>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default AddModal;
