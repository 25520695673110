export const membersTable = [
  "Type",
  "Name",
  "Phone",
  "Status",
  "Member since",
  "Membership active",
  "Action",
];

export const gamesTable = ["Player One", "Game Length", "Created by", "Time"];
export const allGamesTable = [
  "Player One",
  "Player Two",
  "Game Length",
  "Time",
];

export const historyTable = [
  "Table",
  "Time played",
  "Date",
  "Time started",
  "Action",
];

export const historyOrders = [
  "Table",
  "Order time",
  "Employee",
  "Status",
  "Action",
];

