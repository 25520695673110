import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { membersTable } from "../../common/Columns";
import Table from "../../components/datatable/Table";
import AddMemberModal from "../../components/modals/AddMemberModal";
import { addMember, getMembers, getMembershipTypes } from "../../services/Members";
import Select from "../../UI/Select";

const Members = () => {
  const [members, setMembers] = useState([]);
  const [types, setTypes] = useState<Array<{id:number, name:string}>>([]);
  const [addModal, setAddModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const getTypes = async () => {
    try {
      const response = await getMembershipTypes();

      setTypes(response?.data?.types);
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const getAllMembers = async () => {
    try {
      const response = await getMembers();

      setMembers(response?.data?.members);
    } catch (error: any) {
      toast.error(error?.message);
    }
  };
  useEffect(() => {
    getTypes();
    getAllMembers();
  }, []);

  const addSubmitHandler = async (e: any) => {
    e.preventDefault();

    const payload = {
      first_name: e.target.firstName.value,
      last_name: e.target.lastName.value,
      phone: e.target.number.value,
      membership_active: e.target.membership_active.checked ? 1 : 0,
      type: e.target.type.value,
    };

    try {
      setIsLoading(true);

      await addMember(payload);

      toast.success("Member added!");
      getAllMembers();
    } catch (err: any) {
      toast.error(err?.message);
    } finally {
      setIsLoading(false);
      setAddModal(false);
    }
  };

  return (
    <>
      <div className="text-sm breadcrumbs px-4">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Members</li>
        </ul>
      </div>
      <div className="p-4">
        <button
          onClick={() => setAddModal(true)}
          className="btn btn-success text-neutral-100"
        >
          Add new
        </button>

        <Table data={members} columns={membersTable} />
      </div>
      {addModal && (
        <AddMemberModal
          title="Add Member"
          closeModal={() => setAddModal(false)}
          submitHandler={addSubmitHandler}
          isLoading={isLoading}
        >
          <div>
            <label htmlFor="editName" className="label-text dark:text-white">
              Име
            </label>
            <input
              className="py-2 rounded-lg bg-neutral-100 focus:ring-2 ring-success outline-none w-full px-4 dark:bg-neutral-800"
              id="firstName"
            />
          </div>
          <div>
            <label htmlFor="editName" className="label-text dark:text-white">
              Презиме
            </label>
            <input
              className="py-2 rounded-lg bg-neutral-100 focus:ring-2 ring-success outline-none w-full px-4 dark:bg-neutral-800"
              id="lastName"
            />
          </div>
          <div>
            <label htmlFor="editName" className=" label-text dark:text-white">
              Телефон
            </label>
            <input
              className="py-2 rounded-lg bg-neutral-100 focus:ring-2 ring-success outline-none w-full px-4 dark:bg-neutral-800"
              id="number"
            />
          </div>
          <label htmlFor="" className=" label-text dark:text-white">
            Membership type
          </label>
          <Select
            id="type"
            setFilterTable={() => null}
            className="bg-neutral-100 dark:bg-neutral-800 rounded-lg"
          >
            <option value={undefined}>Membership Type</option>
            {types.map((e:{id:number, name:string})=><option value={e.id}>{e.name}</option>)}
          </Select>
          <div className="form-control w-max">
            <label className="label cursor-pointer">
              <input
                type="checkbox"
                className="checkbox checkbox-success checkbox-sm mr-2 "
                id="membership_active"
                defaultChecked
              />
              <span className="label-text dark:text-white">
                Активирај членство
              </span>
            </label>
          </div>
        </AddMemberModal>
      )}
    </>
  );
};

export default Members;
