import api from "../utilities/fetching";

/**
 *
 * @returns Promise - api response
 */
export const getCategories = async () => {
  return await api.get("categories");
};

/**
 * Remove category
 * @param id
 * @returns promise - api response
 */
export const removeCategory = async (id: number) => {
  return await api.delete(`categories/${id}`);
};

/**
 * Edit existing category by ID
 * @param id
 * @param payload (name, image, menu_order, active)
 * @returns promise - api response
 */

export const editCategory = async (id: number, payload: any) => {
  return await api.postImage(`categories/${id}?_method=PUT`, payload);
};

/**
 * Add a new category
 * @param payload (name, image, menu_order, active)
 * @returns promise - api response
 */
export const addCategory = async (payload: any) => {
  return await api.postImage(`categories`, payload );
};
