import { useState } from "react";
import ReactPaginate from "react-paginate";
import { formatTimeAgo } from "../../utilities/common";

const GamesTable = ({ data = [], playerData, columns }: any) => {
  if (data.length === 0 || data == null) return <></>;
  const [currentPage, setCurrentPage] = useState(1);
  const [gamesPerPage] = useState(5);

  const indexOfLastPost = currentPage * gamesPerPage;
  const indexOfFirstPost = indexOfLastPost - gamesPerPage;
  const currentGames = data.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = ({ selected }: any) => {
    setCurrentPage(selected + 1);
  };

  return (
    <div className="overflow-x-auto sm:w-auto sm:mx-8 w-[90vw] mx-auto bg-white dark:bg-neutral-800 rounded-xl mb-24">
      <table className="table">
        {/* head */}
        <thead>
          <tr className="dark:border-b-neutral-700">
            <th></th>
            {columns.map((e: any, index: any) => {
              return (
                <th key={index} className="dark:text-neutral-100">
                  {e}
                </th>
              );
            })}
          </tr>
        </thead>
        {data && (
          <tbody>
            {currentGames.map((e: any, index: number) => {
              return (
                <tr
                  className="hover:bg-neutral-100 dark:hover:bg-neutral-900 border-b-neutral-200 dark:border-b-neutral-700"
                  key={e.id}
                >
                  <th>
                    {index + 1 + gamesPerPage * currentPage - gamesPerPage}
                  </th>
                  <td>{playerData.first_name + " " + playerData.last_name}</td>
                  <td>{e.game_length} час(а)</td>
                  <td>{e.created_by}</td>
                  <td>{formatTimeAgo(e.created_at)}</td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      <div className="border-t-neutral-700 border-t">
        <ReactPaginate
          onPageChange={paginate}
          pageCount={Math.ceil(data.length / gamesPerPage)}
          previousLabel={"PREV"}
          nextLabel={"NEXT"}
          containerClassName={
            "pagination flex gap-2 justify-center text-sm py-4"
          }
          pageLinkClassName={
            "page-number select-none p-2 px-3 bg-neutral-200 dark:bg-neutral-700 rounded-lg"
          }
          previousLinkClassName={
            "page-number text-xs bg-neutral-200 dark:bg-neutral-700 px-3 py-2 rounded-lg select-none"
          }
          nextLinkClassName={
            "page-number text-xs bg-neutral-200 dark:bg-neutral-700 px-3 py-2 rounded-lg select-none"
          }
          activeLinkClassName={
            "active bg-neutral-400 dark:bg-neutral-900 font-semibold"
          }
        />
      </div>
    </div>
  );
};

export default GamesTable;
