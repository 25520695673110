import * as React from "react";

type InputProps = {
  children: React.ReactNode;
  type?: string;
  id: string;
  placeholder: string;
  className?: string;
};
const Input = ({
  children,
  type = "text",
  id,
  placeholder,
  className = "",
}: InputProps) => {
  return (
    <div className="relative h-full shadow-sm">
      <label className="absolute top-1/2 -translate-y-1/2 left-3 text-neutral-400">
        {children}
      </label>
      <input
        type={type}
        name={id}
        id={id}
        placeholder={placeholder}
        className={`px-10 py-2 rounded-lg bg-neutral-100 focus:ring-2 ring-brown-500 outline-none  ${className}`}
      />
    </div>
  );
};

export default Input;
