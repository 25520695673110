import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { EmailIcon, PasswordIcon } from "../../assets/icons";
import { storeTokenInLocalStorage } from "../../common";
import { useUser } from "../../hooks/useUser";
import Input from "../../UI/Input";
import { APP_ROUTES } from "../../utilities/routes";
import api from "../../utilities/fetching";

const Login = () => {
  const navigate = useNavigate();
  const { user, authenticated } = useUser();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (authenticated) {
      navigate(APP_ROUTES.DASHBOARD);
    }
  }, [authenticated, navigate]);

  const submitHandler = async (e: any) => {
    setIsLoading(true);

    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    try {
      const response = await api.post("login", {
        email: email,
        password: password,
      });

      storeTokenInLocalStorage(response?.data?.access_token);
      toast.success("Successfully logged in!");
      navigate(APP_ROUTES.DASHBOARD);
    } catch (err) {
      toast.error(err?.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <section
        className="w-full mt-48 flex justify-center"
        onSubmit={submitHandler}
      >
        <form className="bg-white dark:bg-neutral-800 max-w-md px-8 py-6 rounded-lg w-full mx-4 flex flex-col items-center shadow-lg">
          <h2 className="text-xl font-medium">Welcome back!</h2>
          <div className="mt-8 flex flex-col w-full gap-4">
            <Input
              type="email"
              id="email"
              placeholder="Email"
              className="w-full dark:bg-neutral-700"
            >
              <EmailIcon />
            </Input>
            <Input
              type="password"
              id="password"
              placeholder="Password"
              className="w-full dark:bg-neutral-700"
            >
              <PasswordIcon />
            </Input>
            <div className="form-control w-max">
              <label className="label cursor-pointer">
                <input
                  type="checkbox"
                  className="checkbox checkbox-sm dark:checkbox-primary mr-2"
                />
                <span className="label-text dark:text-neutral-200">
                  Remember me
                </span>
              </label>
            </div>
          </div>
          <button
            type="submit"
            disabled={isLoading}
            className="mt-4 bg-brown-800 dark:bg-neutral-900 py-1 rounded-lg text-neutral-100 w-full"
          >
            {isLoading ? (
              <span className="loading loading-ring loading-sm"></span>
            ) : (
              "Log in"
            )}
          </button>
        </form>
      </section>
    </>
  );
};

export default Login;
