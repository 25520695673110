import { Route, Routes } from "react-router-dom";
import RouteGuard from "./components/helpers/RouteGuard";
import Layout from "./layouts/Layout";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Games from "./pages/games/Games";
import Menu from "./pages/menu/Menu";
import Products from "./pages/menu/products/Products";
import Members from "./pages/members/Members";
import Member from "./pages/members/member/Member";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Public from "./pages/qrmembers/Public";
import Settings from "./pages/settings/Settings";
import Pool from "./pages/pool/Pool";
import PoolHistory from "./pages/history/PoolHistory";
import Billiards from "./pages/billiards/Billiards";
import Tables from "./pages/orders/Tables";
import OrderMenu from "./pages/orders/OrderMenu";
import Orders from "./pages/orders/Orders";
import ActiveOrders from "./pages/orders/ActiveOrders";
import OrderHistory from "./pages/orders/OrderHistory";
import OrderDetails from "./pages/orders/OrderDetails";
import ManageAccounts from "./pages/management/ManageAccounts";
import AddAccount from "./pages/management/AddAccount";

function App() {
  localStorage.getItem("darkMode") === "false"
    ? document.querySelector("html")?.classList.remove("dark")
    : document.querySelector("html")?.classList.add("dark");
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/*"
          element={
            <p className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-3xl uppercase text-center">
              404
              <br /> Page not found
            </p>
          }
        />
        <Route path="/public/members/:id" element={<Public />} />
        <Route path="/public/billiards" element={<Billiards />} />
        <Route path="/break/the/world" element={<button onClick={() => methodDoesNotExist()}>Break the world</button>}/>

        <Route element={<RouteGuard />}>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Menu />} />

            { /* Orders */ }
            <Route path="/orders/tables" element={<Tables />} />
            <Route path="/orders/active" element={<ActiveOrders />} />
            <Route path="/orders/history" element={<OrderHistory />} />
            <Route path="/orders/:orderId/details" element={<OrderDetails />} />
            <Route path="/orders/menu/:tableId" element={<OrderMenu />} />
            <Route path="/orders/:tableId" element={<Orders />} />

            <Route path="/products/:id" element={<Products />} />
            <Route path="/members/games" element={<Games />} />
            <Route path="/billiard" element={<Pool />} />
            <Route path="/history" element={<PoolHistory />} />
            <Route path="/members" element={<Members />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/members/:id" element={<Member />} />

            <Route path="/management/accounts" element={<ManageAccounts/>} />
            <Route path="/management/accounts/register" element={<AddAccount/>} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
