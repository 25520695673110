import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import QuickBar from "./QuickBar";
import {
  getOrderDetails,
  decrementQuantity,
  incrementQuantity,
} from "../../services/Products";
import { useFetch } from "../../hooks/useFetch";

interface OrderItem {
  id: number;
  product: {
    name: string;
  };
  quantity: number;
}

const OrderDetails = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const [refetch, setRefetch] = useState<boolean>(false);
  const { data, isPending } = useFetch(
    () => getOrderDetails(parseInt(orderId || "0")),
    refetch
  );

  if (isPending)
    return <span className="loading loading-ring loading-sm"></span>;

  if (!data || data.success === false) {
    return (
      <>
        <section className="mb-8 px-4 pb-8">
          <h1 className="text-2xl font-bold my-4">Order Details</h1>
          <p className="my-4">No order found with ID: {orderId}</p>
        </section>
      </>
    );
  }

  const orderItems: OrderItem[] = data.data.order_items || [];

  const orderTotal = orderItems.reduce((acc, item) => {
    return acc + item.quantity * item.product.price;
  }, 0); // Initialize accumulator with 0 to handle empty orderItems array

  return (
    <>
      <section className="mb-8 px-4 pb-8">
        <h1 className="text-2xl font-bold my-4">Order Details</h1>
        {orderItems.length > 0 ? (
          <div className="grid grid-cols-1 gap-2 mt-4">
            {orderItems.map((product: OrderItem) => (
              <div
                key={product.id}
                className="p-4 text-center border-2 rounded-lg bg-neutral-700 flex justify-between items-center"
              >
                <p>{product.product.name}</p>
                <div className="flex gap-2 items-center">
                  <p>{product.quantity}x</p>
                  <p>{product.product.price} ден.</p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="mt-4">No items for this order.</p>
        )}
        {data && data.data && (
          <>
            <div className="flex justify-between mt-4">
              <p>Order time:</p>
              <p>{new Date(data.data.order_time).toLocaleString()}</p>
            </div>
            <div className="flex justify-between mt-4">
              <p>Order total:</p>
              <p>{orderTotal} ден.</p>
            </div>
            <div className="flex justify-between mt-4">
              <p>Made by:</p>
              <p>{data.data.username}</p>
            </div>
          </>
        )}
      </section>
    </>
  );
};

export default OrderDetails;
