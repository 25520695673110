import { apiUrl } from "../utilities/common";
import api from "../utilities/fetching";

export async function fetchTables() {
  const response = await api.get(`tables`);
  return await response;
}

export async function toggleSwatch(
  id: number,
  payload: { hours: number; minutes: number; seconds: number }
) {
  const response = await api.patch(`stopwatch/${id}/pause`, payload);
  return await response;
}

export async function controlStopwatch(
  table_id: number,
  payload: {
    hours: number;
    minutes: number;
    seconds: number;
    createdAt?: string;
  } = { hours: 0, minutes: 0, seconds: 0 }
) {
  const response = await api.post(`tables/${table_id}/control`, payload);
  return await response;
}

export async function addTable(payload: { name: string }) {
  const response = api.post(`tables`, payload);
  return await response;
}

export async function changeName(id: number, payload: { name: string }) {
  const response = await api.patch(`tables/${id}`, payload);
  return await response;
}

export async function createSwatch(tableId: number) {
  const response = await api.post(`tables/${tableId}/stopwatch`, {});
  return await response;
}

export async function endSwatch(
  id: number,
  payload: { hours: number; minutes: number; seconds: number }
) {
  const response = await api.patch(`stopwatch/${id}/end`, payload);
  return await response;
}

export const moveStopwatch = async (
  id: number,
  payload: { table_id: number; hours: number; minutes: number; seconds: number }
) => {
  const response = await api.patch(`stopwatch/${id}/move`, payload);
  return await response;
};

export async function fetchTotalHours(filters: {
  table: string | number;
  startDate: Date;
  endDate: Date;
}) {
  let endUrl = `stopwatch/hours?`;
  const formattedStartDate =
    filters.startDate.toISOString() || "2023-06-01T00:00:00.000Z";
  const formattedEndDate =
    filters.endDate.toISOString() || new Date().toISOString();

  endUrl += filters.table ? `&table=${filters.table || "0"}` : "";
  endUrl += `&start=${formattedStartDate}`;
  endUrl += `&end=${formattedEndDate}`;

  const response = await api.get(`${endUrl}`);
  return await response;
}

export async function fetchHistory(
  page: number,
  filters: {
    table: string | number;
    startDate: Date;
    endDate: Date;
    sortBy: string;
  }
) {
  let endUrl = `stopwatch?page=${page}&perPage=10`;
  const formattedStartDate =
    filters.startDate.toISOString() || "2023-06-01T00:00:00.000Z";
  const formattedEndDate =
    filters.endDate.toISOString() || new Date().toISOString();

  endUrl += filters.table ? `&table=${filters.table || "0"}` : "";
  endUrl += `&start=${formattedStartDate}`;
  endUrl += `&end=${formattedEndDate}`;
  endUrl += `&sortBy=${filters.sortBy}`;

  const response = await api.get(`${endUrl}`);
  return await response;
}

export async function deleteHistory(id: number) {
  const response = await api.delete(`stopwatch/${id}`);
  return await response;
}

export async function fetchAvailability() {
  const response = await api.get(`public/billiards`);
  return await response;
}
