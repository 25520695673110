import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import AddTableModal from "../../components/modals/AddTableModal";
import Stopwatch from "../../components/pool/Stopwatch";
import { addTable, fetchTables } from "../../services/Tables";
import { stopwatch } from "../../utilities/types";
import { isAdmin, retryOperation } from "../../utilities/common";
import { syncQueuedActions } from "../../utilities/offline";
import { User } from "../management/ManageAccounts";
interface Table {
  id: number;
  name: string;
  active_stopwatch: Array<stopwatch | undefined>;
  created_at: Date;
  updated_at: Date;
}

const Pool = () => {
  const user: User = useOutletContext();
  const [tables, setTables] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const getTables = async () => {
    try {
      const res = await fetchTables();

      if (res.error) {
        throw res.error;
      }
      setTables(res.data.table);
    } catch (error:unknown) {
      // Handle any other unexpected errors
      toast.error(error?.message);
    }
  };
  useEffect(() => {
    getTables();
  }, []);

  const addSubmitHandler = async (e: HTMLFormElement) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await addTable({ name: e.target.name.value });

      if (res.error) {
        throw res.error;
      }
      getTables();
      toast.success("Added table successfully!");
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setShowModal(false);
      setIsLoading(false);
    }
  };

  return (
    <section className="mb-8">
      {isAdmin(user) && (
        <button
          onClick={() => setShowModal(true)}
          className="m-4 bg-success text-white dark:text-neutral-900 px-4 py-2 rounded-lg font-semibold"
        >
          Add table
        </button>
      )}
      <div className="gridbox mt-4 sm:m-4">
        {tables.map((e: Table) => {
          return (
            <Stopwatch
              key={e?.id}
              table={e}
              getTables={getTables}
              tables={tables}
            />
          );
        })}
      </div>
      {showModal && (
        <AddTableModal
          title="Add Table"
          closeModal={() => setShowModal(false)}
          submitHandler={addSubmitHandler}
          isLoading={isLoading}
        >
          <div>
            <label htmlFor="name" className="label-text dark:text-white">
              Name
            </label>
            <input
              className="py-2 mb-4 rounded-lg bg-neutral-100 focus:ring-2 ring-success outline-none w-full px-4 dark:bg-neutral-800"
              id="name"
            />
          </div>
        </AddTableModal>
      )}
    </section>
  );
};

export default Pool;
