import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import QuickBar from "./QuickBar";
import {
  getTableOrders,
  decrementQuantity,
  incrementQuantity,
} from "../../services/Products";
import { useFetch } from "../../hooks/useFetch";

interface OrderItem {
  id: number;
  product: {
    name: string;
  };
  quantity: number;
}

const Orders = () => {
  const { tableId } = useParams<{ tableId: string }>();
  const [refetch, setRefetch] = useState<boolean>(false);
  const { data, isPending } = useFetch(
    () => getTableOrders(parseInt(tableId || "0")),
    refetch
  );

  if (isPending)
    return <span className="loading loading-ring loading-sm"></span>;

  if (!data || data.success === false) {
    return (
      <>
        <section className="mb-8 px-4 pb-8">
          <div className="mt-4">
            <Link
              to={`/orders/menu/${tableId}`}
              className="px-8 py-2 bg-neutral-700 rounded-lg text-white"
            >
              Back
            </Link>
          </div>
          <h1 className="text-2xl font-bold my-4">Orders - Table {tableId}</h1>
          <p className="my-4">No orders found for this table</p>
        </section>
        {tableId && (
          <QuickBar
            tableId={parseInt(tableId)}
            orders={0}
            active="orders"
          />
        )}
      </>
    );
  }

  const orderItems: OrderItem[] = data.data.order_items || [];

  return (
    <>
      <section className="mb-8 px-4 pb-8">
        <div className="mt-4">
          <Link
            to={`/orders/menu/${tableId}`}
            className="px-8 py-2 bg-neutral-700 rounded-lg text-white"
          >
            Back
          </Link>
        </div>
        <h1 className="text-2xl font-bold my-4">Orders - Table {tableId}</h1>
        {orderItems.length > 0 ? (
          <div className="grid grid-cols-1 gap-2 mt-4">
            {orderItems.map((product: OrderItem) => (
              <div
                key={product.id}
                className="p-4 text-center border-2 rounded-lg bg-neutral-700 flex justify-between items-center"
              >
                <p>{product.product.name}</p>
                <div className="flex gap-2 items-center">
                  <button
                    className="p-2 rounded-lg"
                    onClick={async () => {
                      try {
                        await decrementQuantity(product.id);
                        setRefetch((prev) => !prev);
                      } catch (error) {
                        toast.error(
                          error?.message || "Failed to decrement quantity."
                        );
                      }
                    }}
                  >
                    -
                  </button>
                  <p>{product.quantity}</p>
                  <button
                    className="p-2 rounded-lg"
                    onClick={async () => {
                      try {
                        await incrementQuantity(product.id);
                        setRefetch((prev) => !prev);
                      } catch (error) {
                        toast.error(
                          error?.message || "Failed to increment quantity."
                        );
                      }
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="mt-4">No orders for this table.</p>
        )}
        {data && data.data.order && (
          <div className="flex justify-between mt-4">
            <p>Order time:</p>
            <p>{new Date(data.data.order.order_time).toLocaleTimeString()}</p>
          </div>
        )}
      </section>
      {tableId && (
        <QuickBar
          tableId={parseInt(tableId)}
          orders={orderItems.length}
          active="orders"
        />
      )}
    </>
  );
};

export default Orders
