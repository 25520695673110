import { useEffect, useState } from "react";
import { getCategories } from "../../services/Category";
import { useFetch } from "../../hooks/useFetch";
import {
  createOrder,
  getCategory,
  getRecentProducts,
} from "../../services/Products";
import { toast } from "react-toastify";
import { Category, Product } from "../../utilities/types";
import { Link, useParams } from "react-router-dom";
import QuickBar from "./QuickBar";

const OrderMenu = () => {
  // const user: any = useOutletContext();
  const [orderCount, setOrderCount] = useState(0);
  const { tableId } = useParams();
  const [refetch, setRefetch] = useState(false);
  const { data: categoriesData, isPending: categoriesPending } = useFetch(
    () => getCategories(),
    refetch
  );
  const { data: popularData, isPending: popularPending } = useFetch(
    () => getRecentProducts(),
    refetch
  );

  const filteredCategories = categoriesData?.data.category.filter(
    (category: Category) => {
      return category.name !== "Uncategorized";
    }
  );

  const [selectedCategory, setSelectedCategory] = useState(-1);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleCategory = async (id: number) => {
    setSelectedCategory(id);

    if (id === -1) {
      setSelectedProducts(popularData?.data);
      return;
    }

    try {
      const response = await getCategory(id);
      setSelectedProducts(response.data.category.product);
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleOrder = async (productId: number) => {
    try {
      const response = await createOrder(parseInt(tableId || "0"), productId);
      // if (response.error) throw new Error(response.message);
      toast.success("Item added to order successfully");
    } catch (error) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    handleCategory(selectedCategory);
  }, [popularData, selectedCategory]);

  if (categoriesPending || popularPending)
    return <span className="loading loading-ring loading-sm"></span>;

  if (!tableId) return <div>No table selected</div>;
  return (
    <>
      <section className="mb-8 px-4 pb-8">
        <h1 className="text-2xl font-bold my-4">Orders - Table {tableId}</h1>
        <div className="overflow-x-auto">
          <div className="flex gap-2">
            <button
              onClick={() => handleCategory(-1)}
              className={`${
                selectedCategory === -1
                  ? "px-4 text-black rounded-lg bg-neutral-200"
                  : "px-4 bg-neutral-700 text-white rounded-lg "
              }`}
            >
              Чести
            </button>

            {filteredCategories &&
              filteredCategories.map((category: Category) => (
                <button
                  key={category.id}
                  onClick={() => handleCategory(category.id)}
                  className={`${
                    selectedCategory === category.id
                      ? "px-4 text-black rounded-lg bg-neutral-200"
                      : "px-4 bg-neutral-700 text-white rounded-lg "
                  }`}
                >
                  {category.name}
                </button>
              ))}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2 mt-4">
          {selectedProducts &&
            selectedProducts.map((product: Product) => (
              <button
                key={product.id}
                onClick={() => {
                  setOrderCount((prev) => prev + 1);
                  handleOrder(product.id);
                }}
                className="p-4 text-center border-2 rounded-lg bg-neutral-700 hover:bg-neutral-800 transition-colors"
              >
                <p>{product.name}</p>
              </button>
            ))}
        </div>
      </section>
      <div className="h-8"></div>
      {tableId && <QuickBar orders={orderCount} tableId={parseInt(tableId)} active="tables" />}
    </>
  );
};

export default OrderMenu;
