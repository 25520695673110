import { Chart as ChartJS, registerables } from "chart.js";
import { Bar } from "react-chartjs-2";

const BarChart = ({ data }) => {
  ChartJS.register(...registerables);
  const chartData = {
    labels: data.map((item) => item.tableName),
    datasets: [
      {
        label: "Hours Played",
        data: data.map((item) => parseInt(item.hoursPlayed) || 0),
        backgroundColor: [
          "rgba(64, 224, 208, 0.6)",
          "rgba(255, 127, 80, 0.6)",
          "rgba(50, 205, 50, 0.6)",
          // Add more colors as needed
        ],
      },
    ],
  };

  return (
    <div className="chart-container min-w-0 p-4 w-full bg-white dark:bg-neutral-800 rounded-xl shadow-lg">
      <div className="px-2">
        <h2 style={{ textAlign: "left" }}>Time Played By Table</h2>
        <p
          style={{ textAlign: "left" }}
          className="text-neutral-400 dark:text-neutral-500 text-xs font-semibold py-2"
        >
          Last 30 days
        </p>
      </div>

      <Bar
        data={chartData}
        options={{
          scales: {
            x: {
              type: "category",
              labels: data.map((item) => item.tableName),
            },
            y: {
              beginAtZero: true,
            },
          },
          elements: {
            bar: {
              borderRadius: 10, // Adjust the border radius as needed
            },
          },
          plugins: {
            title: {
              display: false,
              text: "Last 30 days",
            },
            legend: {
              display: false,
            },
          },
        }}
      />
    </div>
  );
};

export default BarChart;
