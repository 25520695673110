import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { EditIcon, GarbageIcon } from "../../../assets/icons";
import AddModal from "../../../components/modals/AddModal";
import EditModal from "../../../components/modals/EditModal";
import {
  addProduct,
  editProduct,
  getCategory,
  removeProduct,
} from "../../../services/Products";

const Products = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts]: any = useState([]);
  const [category, setCategory]: any = useState({});
  const [showModal, setShowModal] = useState(false);
  const [addModal, setAddModal] = useState(false);

  const [editId, setEditId] = useState(0);
  const [editName, setEditName] = useState("");
  const [editPrice, setEditPrice] = useState("");
  const [editOrder, setEditOrder] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [image, setImage] = useState("");
  const [editActive, setEditActive] = useState(true);

  async function getProducts() {
    try {
      const response = await getCategory(id);
      setProducts(response.data.category.product);
      setCategory(response.data.category);
    } catch (error: any) {
      toast.error(error?.message);
    }
  }
  useEffect(() => {
    getProducts();
  }, []);

  const confirmDelete = async (id: number) => {
    const confirmed = window.confirm("Are you sure?");

    if (confirmed) {
      try {
        const response = await removeProduct(id);
        setProducts((prev: any) => prev.filter((e: any) => e.id !== id));
        toast.info("Product successfully removed!");
      } catch (error: any) {
        toast.error(error?.message);
      }
    }
  };

  const editModalHandler = async (id: number) => {
    setShowModal(true);
    const product = products.filter((e: any) => e.id === id);
    setEditName(product[0].name);
    setEditActive(product[0].active);
    setEditOrder(product[0].menu_order);
    setEditPrice(product[0].price);
    setEditDescription(product[0].description);
    setImage(product[0].image_url);
    setEditId(id);
  };

  const submitHandler = async (e: any) => {
    e.preventDefault();
    const payload: any = new FormData();
    e.target.editImage.files[0] &&
      payload.append("image", e.target.editImage.files[0]);
    payload.append("name", editName);
    payload.append("active", editActive);
    payload.append("price", editPrice);
    payload.append("description", editDescription);
    payload.append("menu_order", editOrder);
    try {
      setIsLoading(true);
      const response = await editProduct(editId, payload);

      getProducts();
      toast.success("Product edited!");
      setShowModal(false);
    } catch (err: any) {
      toast.error(err?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const addSubmitHandler = async (e: any) => {
    e.preventDefault();
    const payload: any = new FormData();

    e.target.image.files[0] && payload.append("image", e.target.image.files[0]);
    payload.append("name", e.target.editName.value);
    payload.append("active", e.target.editActive.checked ? 1 : 0);
    payload.append("menu_order", e.target.editOrder.value);
    payload.append("price", e.target.editPrice.value);
    payload.append("description", e.target.editDescription.value);
    payload.append("category", id);

    try {
      setIsLoading(true);
      const response = await addProduct(payload);

      toast.success("Product added!");
      setAddModal(false);
      getProducts();
    } catch (err: any) {
      toast.error(err?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="text-sm breadcrumbs px-6">
        <ul>
          <li>
            <a>Products</a>
          </li>
          <li>
            <Link to="/products">Categories</Link>
          </li>
          <li>{category.name}</li>
        </ul>
      </div>

      <button
        onClick={() => setAddModal(true)}
        className="btn btn-success mx-4 my-4 shadow text-white"
      >
        Add new
      </button>

      <ul className="bg-white dark:bg-neutral-800 mx-4 rounded-lg  mb-24">
        {products.map((e: any) => {
          return (
            <li
              className="flex justify-between items-center bg-white dark:bg-neutral-800 min-w-[700px] px-4 rounded cursor-pointer border-b dark:border-b-neutral-700"
              key={e.id}
            >
              <div className="flex items-center gap-4">
                <p className="w-8">{e.id}</p>
                <div className="w-[150px] object-none aspect-video overflow-hidden flex">
                  <img
                    src={e.image_url}
                    alt={e.name}
                    className="w-full object-cover object-center"
                  />
                </div>
                <div>
                  <h4>{e.name}</h4>
                  <p>{e.price} ден.</p>
                </div>
              </div>
              <div className="flex gap-4 items-center">
                <button
                  onClick={() => {
                    editModalHandler(e.id);
                  }}
                >
                  <EditIcon />
                </button>
                <button
                  onClick={() => {
                    confirmDelete(e.id);
                  }}
                >
                  <GarbageIcon />
                </button>
              </div>
            </li>
          );
        })}
      </ul>
      {showModal && (
        <EditModal
          isLoading={isLoading}
          title="Edit Product"
          closeModal={() => setShowModal(false)}
          submitHandler={submitHandler}
        >
          <div className="w-1/2 overflow-hidden">
            <img src={image} className="object-cover" alt="" />
          </div>
          <div className="form-control w-full -mt-2">
            <label className="label">
              <span className="label-text dark:text-white">Select image</span>
            </label>
            <input
              type="file"
              id="editImage"
              className="file-input w-full bg-neutral-100 dark:bg-neutral-700 dark:file-input-bordered"
            />
          </div>
          <div>
            <label htmlFor="edit-name" className="label-text dark:text-white">
              Name
            </label>
            <input
              value={editName}
              onChange={(e) => {
                setEditName(e.target.value);
              }}
              className="py-2 rounded-lg bg-neutral-100 focus:ring-2 ring-success outline-none w-full px-4 dark:bg-neutral-700"
              id="edit-name"
            />
          </div>
          <div>
            <label htmlFor="editPrice" className=" label-text dark:text-white">
              Price
            </label>
            <input
              value={editPrice}
              onChange={(e) => {
                setEditPrice(e.target.value);
              }}
              className="py-2 rounded-lg bg-neutral-100 focus:ring-2 ring-success outline-none w-full px-4 dark:bg-neutral-700"
              id="editPrice"
            />
          </div>
          <div>
            <label
              htmlFor="edit-name"
              className=" label-text dark:text-white text-white"
            >
              Description
            </label>
            <input
              value={editDescription}
              onChange={(e) => {
                setEditDescription(e.target.value);
              }}
              className="py-2 rounded-lg bg-neutral-100 focus:ring-2 ring-success outline-none w-full px-4 dark:bg-neutral-700"
              id="editDescription"
            />
          </div>
          <div>
            <label htmlFor="edit-name" className=" label-text dark:text-white">
              Menu order
            </label>
            <input
              value={editOrder}
              onChange={(e) => {
                setEditOrder(e.target.value);
              }}
              className="py-2 rounded-lg bg-neutral-100 focus:ring-2 ring-success outline-none w-full px-4 dark:bg-neutral-700"
              id="edit-order"
            />
          </div>
          <div className="form-control w-max">
            <label className="label cursor-pointer">
              <input
                type="checkbox"
                checked={editActive}
                onChange={() => {
                  setEditActive((e) => !e);
                }}
                className="checkbox checkbox-success checkbox-sm mr-2"
              />
              <span className="label-text dark:text-white">Set Active</span>
            </label>
          </div>
        </EditModal>
      )}
      {addModal && (
        <AddModal
          isLoading={isLoading}
          title="Add Product"
          closeModal={() => setAddModal(false)}
          submitHandler={addSubmitHandler}
        >
          <div className="form-control w-full -mt-2">
            <label className="label">
              <span className="label-text dark:text-white">Select image</span>
            </label>
            <input
              type="file"
              id="image"
              className="file-input w-full bg-neutral-100 dark:bg-neutral-700 dark:file-input-bordered"
            />
          </div>
          <div>
            <label htmlFor="editName" className="label-text dark:text-white">
              Name
            </label>
            <input
              className="py-2 rounded-lg bg-neutral-100 focus:ring-2 ring-success outline-none w-full px-4 dark:bg-neutral-700"
              id="editName"
            />
          </div>
          <div>
            <label htmlFor="editPrice" className=" label-text dark:text-white">
              Price
            </label>
            <input
              value={editPrice}
              onChange={(e) => {
                setEditPrice(e.target.value);
              }}
              className="py-2 rounded-lg bg-neutral-100 focus:ring-2 ring-success outline-none w-full px-4 dark:bg-neutral-700"
              id="editPrice"
            />
          </div>
          <div>
            <label htmlFor="edit-name" className=" label-text dark:text-white">
              Description
            </label>
            <input
              value={editDescription}
              onChange={(e) => {
                setEditDescription(e.target.value);
              }}
              className="py-2 rounded-lg bg-neutral-100 focus:ring-2 ring-success outline-none w-full px-4 bg-neutral-700"
              id="editDescription"
            />
          </div>
          <div>
            <label htmlFor="editName" className=" label-text dark:text-white">
              Menu order
            </label>
            <input
              value={editOrder}
              onChange={(e) => {
                setEditOrder(e.target.value);
              }}
              className="py-2 rounded-lg bg-neutral-100 focus:ring-2 ring-success outline-none w-full px-4 dark:bg-neutral-700"
              id="editOrder"
            />
          </div>
          <div className="form-control w-max">
            <label className="label cursor-pointer">
              <input
                type="checkbox"
                checked={editActive}
                onChange={() => {
                  setEditActive((e) => !e);
                }}
                className="checkbox checkbox-success checkbox-sm mr-2"
                id="editActive"
              />
              <span className="label-text dark:text-white">Set Active</span>
            </label>
          </div>
        </AddModal>
      )}
    </>
  );
};

export default Products;
