import { Link, useOutletContext } from "react-router-dom";
import { addTable, getTables } from "../../services/Products";
import { useFetch } from "../../hooks/useFetch";
import AddTableModal from "../../components/modals/AddTableModal";
import { User } from "../management/ManageAccounts";
import { isAdmin } from "../../utilities/common";
import { useState } from "react";
import { toast } from "react-toastify";

export interface Table {
  id: number;
  status: "occupied" | "available" | "reserved";
}

export interface TableDTO {
  data: Table[];
}

const Tables = () => {
  const user: User = useOutletContext();
  const [refetch, setRefetch] = useState(false);
  const { data, isPending } = useFetch<TableDTO>(() => getTables(), refetch);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const addSubmitHandler = async (e: HTMLFormElement) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const name = e.target.name.value;
      const amount = e.target.amount.value;
      console.log(amount);
      if (amount == "1") {
        if (name == "") {
          await addTable({ amount: 1 });
        } else await addTable({ name: name, amount: 1 }); //
      } else if (amount > 1) {
        await addTable({ amount: amount });
      } else {
        throw new Error("Amount cannot be less than 1");
      }
      setRefetch((prev) => !prev);
      toast.success(`Added ${amount} table(s) successfully!`);
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setShowModal(false);
      setIsLoading(false);
    }
  };

  if (isPending)
    return <span className="loading loading-ring loading-sm"></span>;

  return (
    <>
      {isAdmin(user) && (
        <button
          onClick={() => setShowModal(true)}
          className="m-4 bg-success text-white dark:text-neutral-900 px-4 py-2 rounded-lg font-semibold"
        >
          Add table
        </button>
      )}

      <section className="mb-8 px-4">
        <h1 className="text-2xl font-bold mb-4">Tables</h1>
        <div className="grid grid-cols-5 gap-4">
          {data &&
            data?.data.map((table: Table, index: number) => (
              <Link
                to={`/orders/menu/${table.id}`}
                key={index}
                className={`p-4 text-center border-2 rounded-lg ${
                  table.status == "occupied"
                    ? "border-red-600"
                    : "border-emerald-500"
                }`}
              >
                <p>{table.id}</p>
              </Link>
            ))}
        </div>
      </section>
      {showModal && (
        <AddTableModal
          title="Add Table"
          closeModal={() => setShowModal(false)}
          submitHandler={addSubmitHandler}
          isLoading={isLoading}
        >
          <div>
            <label htmlFor="name" className="label-text text-white">
              Name
            </label>
            <input
              className="py-2 mb-4 rounded-lg bg-neutral-700 focus:ring-2 ring-success outline-none w-full px-4"
              id="name"
            />
          </div>
          <div>
            <label htmlFor="amount" className="label-text text-white">
              Amount
            </label>
            <input
              type="text"
              className="py-2 mb-4 rounded-lg bg-neutral-700 focus:ring-2 ring-success outline-none w-full px-4"
              id="amount"
              defaultValue="1"
            />
          </div>
        </AddTableModal>
      )}
    </>
  );
};

export default Tables;
