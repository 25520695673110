import { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import { ChevronDown, ChevronUp } from "../assets/icons";

interface SubMenu {
  id: number;
  name: string;
  route: string;
  access: boolean;
}

const Accordion = ({
  children,
  submenu,
  activeRoute,
  parentLink,
  active,
  callback
}: {
  parentLink: string;
  children: ReactNode;
  submenu: Array<SubMenu>;
  activeRoute: string;
  active: boolean;
  callback:() => void;
}) => {
  // const [showMenu, setShowMenu] = useState(false);
  return (
    <div className="">
      <button
        onClick={() => {
          // setShowMenu((prev) => !prev);
          callback();
          // closeAll();
        }}
        className={`py-2 h-min w-full rounded-xl px-7 mx-1 group-checked:bg-red-700 text-neutral-400 flex justify-between items-center gap-4 hover:border-l-brown-700 hover:border-l-4 transition-all ${
          activeRoute.startsWith(parentLink) &&
          "text-white bg-brown-800 w-[95%]"
        }`}
      >
        {children}
        {active ? <ChevronDown /> : <ChevronUp />}
      </button>
      <div
        className={`transition-all flex flex-col gap-3 origin-top px-14 ${
          active ? "scale-y-100 py-2" : "h-0 scale-y-0"
        }`}
      >
        {submenu.map((e: any) => {
          return (
            <Link
              to={e.route}
              key={e.id}
              className={`text-sm font-medium text-neutral-400 ${
                activeRoute === e.route
                  ? "text-neutral-600 dark:text-white font-semibold"
                  : ""
              }`}
            >
              {e.name}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Accordion;

