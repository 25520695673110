import * as React from "react";
import { BigUsersIcon, CardIcon, MoneyIcon } from "../assets/icons";

const Components: any = {
  cardIcon: CardIcon,
  moneyIcon: MoneyIcon,
  usersIcon: BigUsersIcon,
};
const Cards = ({
  value,
  icon,
  desc,
  text,
}: {
  value: number;
  icon: string;
  desc: string;
  text: string;
}) => {
  return (
    <div className="bg-white dark:bg-neutral-800 rounded-xl md:w-full py-4 px-8 mt-4 shadow-lg">
      <div className="flex items-start justify-between">
        <div>
          <h1 className="text-xl font-semibold">{value?.toLocaleString()}</h1>
          <p className="text-neutral-400 text-sm">{text}</p>
        </div>
        {React.createElement(Components[icon])}
      </div>
      <p className="text-neutral-400 text-xs mt-4">{desc}</p>
    </div>
  );
};

export default Cards;
