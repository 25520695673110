import * as React from "react";
import {
  BigClockIcon,
  TrendingDownIcon,
  TrendingUpIcon,
} from "../assets/icons";

const Components: any = {
  clockIcon: BigClockIcon,
};
const TrendCards = ({
  value,
  icon,
  desc,
  diff,
  text,
  altText,
}: {
  value: number;
  icon: string;
  desc: string;
  diff: number;
  text: string;
  altText: string;
}) => {
  return (
    <div className="bg-white dark:bg-neutral-800 rounded-xl md:w-full py-4 px-8 mt-4 shadow-lg">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-xl font-semibold">{value}</h1>
          <p className="text-neutral-400 text-sm">{text}</p>
        </div>
        {React.createElement(Components[icon])}
      </div>
      {diff >= 0 ? (
        <div className="flex items-center gap-2">
          <TrendingUpIcon />
          <p className="text-success text-xs font-semibold mt-4 flex flex-col">
            {desc} <span className="text-neutral-500">{altText}</span>
          </p>
        </div>
      ) : (
        <div className="flex items-center gap-2">
          <TrendingDownIcon />
          <p className="text-danger text-xs font-semibold mt-4 flex flex-col">
            {desc} <span className="text-neutral-500">{altText}</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default TrendCards;
