import { useOutletContext } from "react-router-dom";
import AdminHome from "../../components/home/AdminHome";
import EmployeeHome from "../../components/home/EmployeeHome";
import { User } from "../management/ManageAccounts";
import { isAdmin } from "../../utilities/common";

const Home = () => {
  const user: User = useOutletContext();
  return (
    <>
      <div className="text-sm breadcrumbs px-4">
        <ul>
          <li>
            <a>Dashboard</a>
          </li>
        </ul>
      </div>
      {isAdmin(user) ? <AdminHome /> : <EmployeeHome />}
    </>
  );
};

export default Home;
