import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { clearTable } from "../../services/Products";

interface QuickBarProps {
  tableId?: number;
  orders: number | null;
  active: "tables" | "orders";
}

const QuickBar = ({ tableId, orders, active = "tables" }: QuickBarProps) => {
  const navigate = useNavigate();
  const handleClearTable = async () => {
    try {
      await clearTable(tableId || 0);
      toast.success("Table cleared successfully");
      navigate("/orders/tables");
    } catch (error) {
      toast.error(error?.message);
    }
  };

  return (
    <section className="fixed bottom-0 left-0 flex gap-0 bg-neutral-700 justify-between w-full">
      <button
        onClick={() => navigate("/orders/tables")}
        className={`px-8 py-4 rounded-lg w-full ${active === "tables" ? "bg-neutral-200 text-black" : "bg-neutral-700 text-white"}`}
      >
        <p>Tables</p>
      </button>
      {orders !== null && (
        <button
          onClick={() => navigate(`/orders/${tableId}`)}
          className={`px-8 py-4 rounded-lg w-full ${active === "orders" ? "bg-neutral-200 text-black" : "bg-neutral-700 text-white"}`}
        >
          <p>Orders</p>
        </button>
      )}
      <button
        className="px-8 py-4 bg-neutral-700 rounded-lg w-full"
        onClick={handleClearTable}
      >
        Clear Table
      </button>
    </section>
  );
};

export default QuickBar;
