import api from "../utilities/fetching";

export const getActiveMembers = async () => {
  return await api.get("members/active");
};
export const addMembership = async (id: any) => {
  return await api.post(`members/${id}/membership`);
};

export const endMembership = async (id: any) => {
  return await api.delete(`members/${id}/membership`);
};

export const getMembers = async () => {
  return await api.get("members");
};

export const getMembershipTypes = async () => {
  return await api.get("memberships");
};

export const getGames = async () => {
  return await api.get("games");
};

export const createGame = async (payload: any) => {
  return await api.post("games", payload);
};

export const addMember = async (payload: any) => {
  return await api.post("members", payload);
};

export const getMember = async (id: any) => {
  return await api.get(`members/${id}`);
};
export const getPublicMember = async (id: any) => {
  return await api.get(`public/members/${id}`);
};

export const deleteMember = async (id: any) => {
  return await api.delete(`members/${id}`);
};
