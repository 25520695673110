import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FacebookIcon, InstagramIcon, Logo } from "../../assets/icons";
import { getPublicMember } from "../../services/Members";

const Public = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [member, setMember]: any = useState({});
  const [memberships, setMemberships]: any = useState({});
  const [hours, setHours]: any = useState({});

  const getMembers = async () => {
    try {
      setIsLoading(true);
      const response = await getPublicMember(id);

      setMember(response.data.data.member);
      setMemberships(response.data.data.memberships);
      setHours(response.data.data.total);
    } catch (err: any) {
      toast.error("Something went wrong");
      console.log(err.response.error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMembers();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="loading loading-sm fixed top-1/2 left-1/2"></div>
      ) : (
        <>
          <div className="absolute inset-0 -z-50 bg-neutral-900">
            <div className="m-4 flex flex-col md:flex-wrap items-center shadow-xl py-8">
              <Logo width={200} height={200} />
              <div className="flex gap-4 mt-8 bg-neutral-800 text-white w-full xl:w-1/3 rounded-lg p-8">
                <div className="rounded-xl overflow-hidden">
                  <img
                    src={`https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=https://admin.thefirst.mk/public/members/${member.id}`}
                    alt="qrcode"
                    className="p-1 rounded-lg"
                  />
                  {/* <UserIcon /> */}
                </div>
                <div className="">
                  <h3 className="text-xs tracking-wide uppercase text-neutral-400">
                    {memberships[0].type.name}
                  </h3>
                  <h2 className="font-medium">
                    {(member.first_name || "Member not") +
                      " " +
                      (member.last_name || " found")}
                  </h2>
                  <p className="text-sm text-neutral-300 ml-">{member.phone}</p>
                  {member.membership_active == "1" ? (
                    <p className="mt-2 bg-success text-sm py-0.5 px-2 text-white font-medium rounded-2xl w-max">
                      {memberships[0]?.status}
                    </p>
                  ) : (
                    <p className="mt-2 bg-danger py-0.5 text-sm px-2 text-white font-medium rounded-2xl w-max">
                      {memberships[0]?.status || "not active"}
                    </p>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 mt-4 bg-neutral-800 text-white shadow-xl rounded-lg w-full xl:w-1/3 p-6">
                <div>
                  <p className="text-sm font-medium">Member since:</p>
                  <p className="text-sm text-neutral-500">
                    {memberships[memberships.length - 1]?.active_from || "None"}
                  </p>
                </div>
                <div>
                  <p className="text-sm font-medium">Subscription active:</p>
                  <p className="text-sm text-neutral-500">
                    {memberships[0]?.active_to || "None"}
                  </p>
                </div>
             </div>
            </div>
            <div className="flex gap-4 justify-center">
              <a href="https://instagram.com/thefirstmk" target="_blank">
                <FacebookIcon />
              </a>
              <a href="https://instagram.com/thefirstmk" target="_blank">
                <InstagramIcon />
              </a>
            </div>
            <p className="text-center text-neutral-400 text-xs absolute bottom-2 left-1/2 -translate-x-1/2">
              &copy; 2023 All rights reserved
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default Public;
