import { useEffect, useState } from "react";
import { formatDate, padWithLeadingZeros } from "../../utilities/common";
import ReactPaginate from "react-paginate";
import { GarbageIcon } from "../../assets/icons";

const HistoryTable = ({
  data,
  columns,
  handleDelete,
  pageData,
  setPagination,
  isLoading,
}: any) => {
  if (data.length === 0) return <></>;
  const currentPage = pageData.current_page;
  const [gamesPerPage] = useState(pageData.per_page);

  const paginate = ({ selected }: any) => {
    setPagination(selected + 1);
  };

  return (
    <div className="overflow-x-auto sm:w-auto sm:mx-8 mt-8 bg-white w-[90vw] mx-auto dark:bg-neutral-800 rounded-xl mb-24">
      <table className="table block">
        {/* head */}
        <thead>
          <tr className="dark:border-b-neutral-700">
            <th></th>
            {columns.map((e: any, index: any) => {
              return (
                <th key={index} className="dark:text-neutral-100">
                  {e}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data && !isLoading
            ? data.map((e: any, index: number) => {
                return (
                  <tr
                    className="hover:bg-neutral-100 dark:hover:bg-neutral-900 border-b-neutral-200 dark:border-b-neutral-700"
                    key={e.id}
                  >
                    <th>
                      {index + 1 + gamesPerPage * currentPage - gamesPerPage}
                    </th>
                    <td>{e.table?.name || "Table removed"}</td>
                    <td>
                      {padWithLeadingZeros(e.hours, 2)}:
                      {padWithLeadingZeros(e.minutes, 2)}:
                      {padWithLeadingZeros(e.seconds, 2)}
                    </td>
                    <td>{formatDate(e.created_at)}</td>
                    <td>
                      {new Date(e.created_at).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          handleDelete(e.id);
                        }}
                      >
                        <GarbageIcon />
                      </button>
                    </td>
                  </tr>
                );
              })
            : [...Array(10)].map((e, index: number) => (
                <tr
                  key={index}
                  className="hover:bg-neutral-100 dark:hover:bg-neutral-900 border-b-neutral-200 dark:border-b-neutral-700"
                >
                  <td className="">
                    <p className="animate-pulse rounded bg-neutral-700 px-1 py-2 my-1"></p>
                  </td>
                  <td className="">
                    <p className="animate-pulse rounded bg-neutral-700 px-1 py-2"></p>
                  </td>
                  <td className="">
                    <p className="animate-pulse rounded bg-neutral-700 px-1 py-2"></p>
                  </td>
                  <td className="">
                    <p className="animate-pulse rounded bg-neutral-700 px-1 py-2"></p>
                  </td>
                  <td className="">
                    <p className="animate-pulse rounded bg-neutral-700 px-1 py-2"></p>
                  </td>
                  <td className="">
                    <p className="animate-pulse rounded bg-neutral-700 px-1 py-2"></p>
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
      <div className="border-t-neutral-700 border-t">
        <ReactPaginate
          onPageChange={paginate}
          pageCount={Math.ceil(pageData.total / gamesPerPage)}
          previousLabel={"PREV"}
          nextLabel={"NEXT"}
          containerClassName={
            "pagination flex gap-2 justify-center text-sm py-4"
          }
          pageLinkClassName={
            "page-number select-none p-2 px-3 bg-neutral-200 dark:bg-neutral-700 rounded-lg"
          }
          previousLinkClassName={
            "page-number text-xs bg-neutral-200 dark:bg-neutral-700 px-3 py-2 rounded-lg select-none"
          }
          nextLinkClassName={
            "page-number text-xs bg-neutral-200 dark:bg-neutral-700 px-3 py-2 rounded-lg select-none"
          }
          activeLinkClassName={
            "active bg-neutral-400 dark:bg-neutral-900 font-semibold"
          }
        />
      </div>
    </div>
  );
};

export default HistoryTable;
