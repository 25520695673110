const API_URL = import.meta.env.VITE_APP_ENV === 'dev' ? import.meta.env.VITE_API_DEV_URL : import.meta.env.VITE_API_PROD_URL;
export const API_ROUTES = {
  SIGN_IN: `${API_URL}/login`,
  GET_USER: `${API_URL}/user`,
};

export const APP_ROUTES = {
  SIGN_IN: "/login",
  DASHBOARD: "/",
};
