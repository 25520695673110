import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Outlet, useOutletContext } from "react-router-dom";
import { useSwipe } from "../hooks/useSwipe";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { User } from "../pages/management/ManageAccounts";

const Layout = () => {
  const user: User = useOutletContext();
  const [showSidebar, setShowSidebar] = useState(true);

  const swipe = useSwipe({
    left: () => setShowSidebar(false), // Adjust the logic as needed
    right: () => setShowSidebar(true), // Adjust the logic as needed
  });

  const desktopScreen = useMediaQuery({ query: `(min-width: 640px)` });

  const sideBarHandler = () => {
    setShowSidebar((prev) => !prev);
  };

  useEffect(() => {
    if (!desktopScreen) {
      setShowSidebar(false);
    }
    if (desktopScreen) {
      setShowSidebar(true);
    }
  }, [desktopScreen]);

  return (
    <>
      {user ? (
        <>
          <main className="w-full">
            <Sidebar
              role={user?.user_roles?.role_name}
              className={showSidebar ? "translate-x-0" : "-translate-x-full"}
            />
            <section
              className={`${
                showSidebar && "pl-[250px]"
              } transition-all duration-300`}
            >
              <Navbar
                className={showSidebar && "pr-[250px]"}
                user={user}
                sideBarHandler={sideBarHandler}
              />
              <div className="pt-[4.5rem]">{<Outlet context={user} />}</div>
            </section>
          </main>
        </>
      ) : (
        <div className="loading loading-sm fixed top-1/2 left-1/2"></div>
      )}
    </>
  );
};

export default Layout;
