import * as React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  BookIcon,
  ClockIcon,
  GameIcon,
  HomeIcon,
  Logo,
  LogoutIcon,
  PoolIcon,
  SettingsIcon,
  UsersIcon,
} from "../assets/icons";
import Links from "../constants/Links";
import { logout } from "../services/User";
import Accordion from "../UI/Accordion";
import { ContactRound, Package } from "lucide-react";

const Components: any = {
  home: HomeIcon,
  menu: BookIcon,
  users: UsersIcon,
  games: GameIcon,
  pool: PoolIcon,
  history: ClockIcon,
  orders: Package,
  employees: ContactRound,
};

const isParentRoute = (parent: string, child: string) => {
  const parentArray = parent.split("/");
  const childArray = child.split("/");
  if (parentArray.length === 1) return false;
  if (parentArray.length > childArray.length) return false;
  for (let i = 0; i < parentArray.length; i++) {
    if (parentArray[i] !== childArray[i]) return false;
  }
  return true;
};

const Sidebar = ({ role, className }: any) => {
  const activeRoute = useLocation();
  const navigate = useNavigate();
  const [activeId, setActiveId] = React.useState<number>(-1);
  return (
    <aside
      className={`bg-neutral-100 dark:bg-brown-900 duration-300 top-0 flex flex-col z-10 fixed w-[250px] h-[100dvh] transition-all ${className}`}
    >
      {/* <h1 className="p-4 uppercase font-semibold">The First</h1> */}
      <div className="mt-4 ml-4">
        <Logo />
      </div>
      <div className="mt-auto mb-16">
        {Links.filter((e) => e.access || role === "ADMIN").map((l: any) => {
          if (!l.drawer) {
            return (
              <Link
                key={l.id}
                to={l.route}
                className={`py-2 rounded-lg px-7 mx-1 text-neutral-400 flex items-center gap-4 dark:hover:border-l-brown-700 hover:border-l-indigo-500 hover:border-l-4 transition-all ${
                  activeRoute.pathname === l.route ||
                  isParentRoute(l.route, activeRoute.pathname)
                    ? "text-white dark:text-white font-medium bg-indigo-600 dark:bg-brown-800 "
                    : ""
                }`}
              >
                {React.createElement(Components[l.icon])}
                {l.name}
              </Link>
            );
          } else {
            return (
              <Accordion
                key={l.id}
                parentLink={l.route}
                submenu={l.children}
                activeRoute={activeRoute.pathname}
                active={l.id === activeId}
                callback={() => {
                    if (activeId === l.id) {
                      setActiveId(-1);
                      return;
                    }
                    setActiveId(l.id)
                }}
              >
                <div className="flex items-center gap-4">
                  {React.createElement(Components[l.icon])}
                  {l.name}
                </div>
              </Accordion>
            );
          }
        })}
      </div>

      <div className="mt-auto mb-12">
        <Link
          to={"/settings"}
          className={`py-2 rounded-xl px-7 mx-1 text-neutral-400 flex items-center gap-4 dark:hover:border-l-brown-700 hover:border-l-indigo-500 hover:border-l-4 transition-all ${
            activeRoute.pathname === "/settings"
              ? "text-white dark:text-white font-medium bg-indigo-600 dark:bg-brown-800 "
              : ""
          }`}
        >
          <SettingsIcon />
          Settings
        </Link>
        <button
          onClick={async () => {
            const token = localStorage.getItem("token") || "";
            localStorage.removeItem("token");
            navigate("/login");
            await logout(token);
          }}
          className={`py-2 rounded-xl px-7 mx-1 text-neutral-400 flex items-center gap-4 dark:hover:border-l-brown-700 hover:border-l-indigo-500 hover:border-l-4 transition-all`}
        >
          <LogoutIcon />
          Log out
        </button>
      </div>
    </aside>
  );
};

export default Sidebar;
