import { User } from "../pages/management/ManageAccounts";

const formatter = new Intl.RelativeTimeFormat(undefined, {
  numeric: "always",
});

const DIVISIONS = [
  { amount: 60, name: "seconds" },
  { amount: 60, name: "minutes" },
  { amount: 24, name: "hours" },
  { amount: 7, name: "days" },
  { amount: 4.34524, name: "weeks" },
  { amount: 12, name: "months" },
  { amount: Number.POSITIVE_INFINITY, name: "years" },
];

export function formatTimeAgo(date: any) {
  const dateThen: any = new Date(date);
  const dateNow: any = new Date();
  let duration = (dateThen - dateNow) / 1000;

  for (let i = 0; i < DIVISIONS.length; i++) {
    const division: any = DIVISIONS[i];
    if (Math.abs(duration) < division.amount) {
      return formatter.format(Math.round(duration), division.name);
    }
    duration /= division.amount;
  }
}

export const apiUrl =
  import.meta.env.VITE_APP_ENV === "dev"
    ? import.meta.env.VITE_API_DEV_URL
    : import.meta.env.VITE_API_PROD_URL;

export function formatDate(date: Date) {
  return new Date(date).toDateString();
}

export function padWithLeadingZeros(num: number, totalLength: number) {
  return String(num).padStart(totalLength, "0");
}

export function seperateDate(
  date: Date,
  time: {
    seconds: number | undefined;
    minutes: number | undefined;
    hours: number | undefined;
  }
) {
  const now: Date = new Date();
  const stopDate: Date = new Date(date);
  const ms = now.getTime() - stopDate.getTime();
  let seconds = Math.floor(ms / 1000) + (time.seconds || 0);
  let minutes = Math.floor(seconds / 60) + (time.minutes || 0);
  const hours = Math.floor(minutes / 60) + (time.hours || 0);

  seconds = seconds % 60;
  minutes = minutes % 60;

  return { hours, minutes, seconds };
}

export function getTotalHours(
  games: Array<{ hours: number; minutes: number; seconds: number }>
) {
  // Calculate the total seconds from the individual components for all games
  let totalSeconds = 0;
  for (const game of games) {
    totalSeconds += game.hours * 3600 + game.minutes * 60 + game.seconds;
  }

  // Calculate total hours, minutes, and seconds
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  // Format the result as "HH:mm:ss"
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export const retryOperation = async (
  operation: () => Promise<any>,
  times = 3,
  delayBetweenAttempts = 1000
) => {
  let err = "";
  for (let attempt = 1; attempt <= times; attempt++) {
    try {
      const result = await operation();
      return result;
    } catch (error) {
      console.log(error);
      err =
        error?.response?.data?.error?.message ||
        "Something went wrong with the internet connection";
      if (error?.response?.status < 500) throw new Error(err);
      console.log("Attempt", attempt, "failed. Retrying...");
      await new Promise((resolve) => setTimeout(resolve, delayBetweenAttempts));
    }
  }

  throw new Error(err);
};

export const isAdmin = (user: User) => {
  return user?.user_roles?.role_name === "ADMIN";
};
