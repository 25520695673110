import { Outlet } from "react-router-dom";
import { useUser } from "../../hooks/useUser";

const RouteGuard = () => {
  const { user }: any = useUser();

  return <Outlet context={user} />;
};

export default RouteGuard;
