import { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import { gamesTable } from "../../../common/Columns";
import GamesTable from "../../../components/datatable/GamesTable";
import {
  addMembership,
  deleteMember,
  endMembership,
  getMember,
} from "../../../services/Members";
import MemberCard from "../../../components/members/MemberCard";

const Member = () => {
  const { id } = useParams();
  const user: any = useOutletContext();
  const [isLoading, setIsLoading] = useState(true);
  const [member, setMember]: any = useState({});
  const [memberships, setMemberships]: any = useState({});
  const [hours, setHours]: any = useState({});
  const [games, setGames]: any = useState({});

  const navigate = useNavigate();

  const getMembers = async () => {
    try {
      setIsLoading(true);
      const response = await getMember(id);

      setMember(response.data.member);
      setGames(response.data.games);
      setMemberships(response.data.memberships);
      setHours(response.data.total);
    } catch (err: any) {
      toast.error(err?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const removeMember = async () => {
    const confirmed = window.confirm(
      "Are you sure? You cannot undo this action."
    );

    if (confirmed) {
      try {
        setIsLoading(true);
        await deleteMember(id);
        toast.success("Member removed!");

        navigate("/members");
      } catch (err: any) {
        toast.error("Something went wrong");
        console.log(err);
      } finally {
        setIsLoading(true);
      }
    }
  };

  const updateMembership = async () => {
    const confirmed = window.confirm("Are you sure?");

    if (confirmed) {
      try {
        setIsLoading(true);
        await addMembership(id);
        toast.success("Renewed membership!");
        getMembers();
      } catch (err: any) {
        toast.error(err?.message);
      } finally {
        setIsLoading(true);
      }
    }
  };

  const removeMembership = async () => {
    const confirmed = window.confirm("Are you sure?");

    if (confirmed) {
      try {
        setIsLoading(true);
        await endMembership(id);
        toast.info("Ended membership!");
        getMembers();
      } catch (err: any) {
        toast.error(err?.message);
      } finally {
        setIsLoading(true);
      }
    }
  };

  useEffect(() => {
    getMembers();
  }, []);

  if (user.user_roles.role_name !== "ADMIN") {
    return <MemberCard member={member} memberships={memberships} hours={hours} updateMembership={updateMembership}/>
  }

  return (
    <>
      {isLoading ? (
        <div className="loading loading-sm fixed top-1/2 left-1/2"></div>
      ) : (
        <>
          <div className="m-4 flex flex-col md:flex-wrap ">
            <div className="text-sm breadcrumbs px-4 mb-4">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/members">Members</Link>
                </li>
                <li>{member.first_name + " " + member.last_name}</li>
              </ul>
            </div>
            <div className="flex gap-4 bg-white dark:bg-neutral-800 w-full xl:w-1/3 rounded-lg p-8">
              <div className="rounded-xl overflow-hidden">
                <img
                  src={`https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=https://admin.thefirst.mk/public/members/${member.id}`}
                  alt="qrcode"
                />
                {/* <UserIcon /> */}
              </div>
              <div className="">
                <h3 className="text-xs tracking-wide uppercase text-neutral-400">{memberships[0]?.type.name}</h3>
                <h2 className="font-medium">
                  {member.first_name + " " + member.last_name}
                </h2>
                <p className="text-sm text-neutral-700 dark:text-neutral-300">
                  {member.phone}
                </p>
                {member.membership_active == "1" ? (
                  <p className="mt-2 bg-success text-sm py-0.5 px-2 text-white font-medium rounded-2xl w-max">
                    {memberships[0]?.status}
                  </p>
                ) : (
                  <p className="mt-2 bg-danger py-0.5 text-sm px-2 text-white font-medium rounded-2xl w-max">
                    {memberships[0]?.status || "not active"}
                  </p>
                )}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4 bg-white dark:bg-neutral-800 shadow rounded-lg w-full xl:w-1/3 p-6">
              <div>
                <p className="text-sm font-medium">Member since:</p>
                <p className="text-sm text-neutral-500">
                  {memberships[memberships.length - 1]?.active_from || "None"}
                </p>
              </div>
              <div>
                <p className="text-sm font-medium">Subscription active:</p>
                <p className="text-sm text-neutral-500">
                  {memberships[0]?.active_to || "None"}
                </p>
              </div>
              <div>
                <p className="text-sm font-medium">Monthly hours:</p>
                <p
                  className={`text-sm text-neutral-500 font-semibold ${
                    parseInt(hours.sumHours) < memberships[0]?.type.min_hours
                      ? "text-danger"
                      : "text-success"
                  }`}
                >
                  {hours.sumHours || 0}
                </p>
              </div>
              <div>
                <p className="text-sm font-medium">Total hours:</p>
                <p className="text-sm text-neutral-500 font-semibold">
                  {hours.totalHours || 0}
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-2 mt-4 bg-white dark:bg-neutral-800 shadow rounded-lg w-full xl:w-1/3 p-4">
              {member.membership_active == "0" ? (
                <>
                  <button
                    onClick={updateMembership}
                    className="text-white bg-green-600 px-4 py-2 rounded-lg transition-colors hover:bg-green-500"
                  >
                    Renew Membership
                  </button>
                </>
              ) : (
                <button
                  onClick={removeMembership}
                  className="text-white bg-neutral-700 hover:bg-neutral-600 transition-colors px-4 py-2 rounded-lg"
                >
                  End Membership
                </button>
              )}
              <button
                onClick={removeMember}
                className="text-red-100 bg-red-700 hover:bg-red-600 transition-colors px-4 py-2 rounded-lg"
              >
                Delete Member
              </button>
            </div>
          </div>
          <div className="mx-4 mt-8 w-[89vw] sm:w-auto overflow-x-auto">
            <p className="mb-4 font-semibold">Games</p>
            <GamesTable data={games} columns={gamesTable} playerData={member} />
          </div>
        </>
      )}
    </>
  );
};

export default Member;
