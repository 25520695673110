import { useState, useEffect } from "react";
import { toast } from "react-toastify";

function useFetch<T>(
  callback: () => unknown,
  triggerFetch: boolean | URLSearchParams
) {
  const [data, setData] = useState<T | null>(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    const fetchData = async () => {
      setIsPending(true);
      try {
        const response = await callback();

        //@ts-expect-error no way of knowing if response has error property
        if (response.error) throw new Error(response.message);
        setIsPending(false);
        setData(response);
        setError(null);
      } catch (error) {
        toast.error(error?.message);
        setError(`${error} Could not fetch data `);
        setIsPending(false);
      }
    };
    fetchData();
  }, [triggerFetch]);
  return { data, isPending, error };
}
export { useFetch };
