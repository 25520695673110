import { toast } from "react-toastify";
import api from "../utilities/fetching";

export function storeTokenInLocalStorage(token: any) {
  localStorage.removeItem("token");
  localStorage.setItem("token", token);
}

export function getTokenFromLocalStorage() {
  return localStorage.getItem("token");
}

export async function getAuthenticatedUser() {
  const loggedState = { authenticated: false, user: null };
  try {
    const token = getTokenFromLocalStorage();
    if (!token) {
      return loggedState;
    }
    const response = await api.get("user");

    const authenticated = response?.data != null;
    return authenticated
      ? { authenticated: authenticated, user: response?.data?.user }
      : { authenticated: false, user: null };
  } catch (err: any) {
      toast.error(err?.message)
    return loggedState;
  }
}
