import React, { useState } from "react";
import { toast } from "react-toastify";
import { registerUser } from "../../services/User";

const AddAccount = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.warn("Passwords do not match");
      return;
    }
    try {
      const userData = {
        name,
        email,
        password,
        password_confirmation: confirmPassword,
      };
      await registerUser(userData);
      toast.success("Account registered successfully");
      // Clear input fields after successful submission
      setName("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
    } catch (err) {
      toast.error(err?.message);
    }
  };

  return (
    <form className="m-4" onSubmit={submitHandler}>
      <h2 className="mb-8">Register Account</h2>
      <div className="flex flex-col gap-2">
        <label className="label-text text-neutral-500" htmlFor="name">
          Name
        </label>
        <input
          id="name"
          type="text"
          placeholder="Enter name"
          required
          className="px-4 py-2 rounded-lg dark:bg-neutral-800"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <label className="label-text text-neutral-500" htmlFor="email">
          Email
        </label>
        <input
          id="email"
          type="email"
          placeholder="Enter email"
          required
          className="px-4 py-2 rounded-lg dark:bg-neutral-800"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label className="label-text text-neutral-500" htmlFor="password">
          Password
        </label>
        <input
          id="password"
          type="password"
          placeholder="Enter password"
          required
          className="px-4 py-2 rounded-lg dark:bg-neutral-800"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <label className="label-text text-neutral-500" htmlFor="confirmPassword">
          Confirm Password
        </label>
        <input
          id="password_confirmation"
          type="password"
          placeholder="Confirm password"
          required
          className="px-4 py-2 rounded-lg dark:bg-neutral-800"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </div>
      <button className="px-4 py-2 mt-4 hover:bg-success transition-colors bg-green-500 rounded-lg text-white">
        Register
      </button>
    </form>
  );
};

export default AddAccount;

