export const getWithCaching = async (key, apiCall, cacheExpiration = 1 * 24 * 60 * 60 * 1000) => {
  const cachedData = sessionStorage.getItem(key);
  const cachedExpiration = sessionStorage.getItem(`${key}_expiration`);

  if (cachedData && cachedExpiration && new Date().getTime() < Number(cachedExpiration)) {
    return JSON.parse(cachedData);
  }

  try {
    const response = await apiCall();

    sessionStorage.setItem(key, JSON.stringify(response));

    const expirationTime = new Date().getTime() + cacheExpiration;
    sessionStorage.setItem(`${key}_expiration`, expirationTime.toString());

    return response;
  } catch (error) {
    console.error(`Error fetching ${key}:`, error);
    throw error;
  }
};
