import React from "react";
import { useFetch } from "../../hooks/useFetch";
import { Loader2Icon, LoaderCircle, LoaderIcon } from "lucide-react";
import { getUsers, toggleActive } from "../../services/User";
import { toast } from "react-toastify";

export interface User {
  id: number;
  name: string;
  email: string;
  last_login: string;
  enabled: boolean;
  user_roles?: {
    role_name: string;
  };
  role?: string;
}

export interface UserDTO {
  data: {
    users: User[];
  };
}

const columns = ["Name", "Email", "Role", "Enabled", "Last Login"];

const ManageAccounts = () => {
  // Simulating fetching data by setting isPending to false after 1 second
  const { data, isPending } = useFetch<UserDTO>(() => getUsers(), false);

  const toggleAccount = async (e: HTMLInputElement, id: number) => {
    try {
      await toggleActive(id, e.checked);
      toast.info(`Account is now ${e.checked ? "enabled" : "disabled"}`);
    } catch (error: any) {
      toast.error(error?.message);
      console.log(error.response);
    }
  };

  const removeAccount = (userId: number) => {
    // Logic to remove account
    console.log("Remove account:", userId);
  };

  const users = data?.data.users || [];

  if (isPending)
    return (
      <LoaderCircle className="w-10 h-10 text-primary-500 animate-spin mx-auto mt-8" />
    );
  return (
    <section>
      <h1 className="text-2xl font-bold my-4 px-4">Manage Accounts</h1>
      <div className="mt-8 overflow-x-auto sm:w-auto sm:mx-4 w-[90vw] mx-auto bg-white dark:bg-neutral-800 rounded-xl mb-24">
        <table className="table">
          {/* head */}
          <thead>
            <tr className="dark:border-b-neutral-700">
              <th></th>
              {columns.map((e: any, index: any) => {
                return (
                  <th key={index} className="dark:text-neutral-100">
                    {e}
                  </th>
                );
              })}
            </tr>
          </thead>
          {data && (
            <tbody>
              {users.map((e: any, index: number) => {
                return (
                  <tr
                    className="hover:bg-neutral-100 dark:hover:bg-neutral-900 border-b-neutral-200 dark:border-b-neutral-700"
                    key={e.id}
                  >
                    <th>{e.id}</th>
                    <td>{e.name}</td>
                    <td>{e.email}</td>
                    <td>{e.role}</td>
                    <td>
                      {" "}
                      <input
                        type="checkbox"
                        className="toggle toggle-sm toggle-success"
                        defaultChecked={e.enabled}
                        onChange={(el) => {
                          toggleAccount(el.target, e.id);
                        }}
                      />
                    </td>
                    <td>{new Date(e.last_login).toLocaleDateString()}</td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </section>
  );
};

export default ManageAccounts;
