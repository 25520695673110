import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import QuickBar from "./QuickBar";
import {
  getTableOrders,
  decrementQuantity,
  incrementQuantity,
  getActiveOrders,
  clearTable,
} from "../../services/Products";
import { useFetch } from "../../hooks/useFetch";

interface OrderItem {
  id: number;
  product: {
    name: string;
  };
  quantity: number;
}

const ActiveOrders = () => {
  const [refetch, setRefetch] = useState<boolean>(false);
  const { data, isPending } = useFetch(() => getActiveOrders(), refetch);

  if (isPending)
    return <span className="loading loading-ring loading-sm"></span>;

  if (!data || data.success === false) {
    return (
      <>
        <section className="mb-8 px-4 pb-8">
          <h1 className="text-2xl font-bold my-4">Active Orders</h1>
          <p className="my-4">No active orders</p>
        </section>
      </>
    );
  }

  const handleCloseOrder = async (id:number) => {
    try {
      await clearTable(id);
      toast.success("Order closed successfully");
      setRefetch((prev) => !prev);
    } catch (error) {
      toast.error(error?.message);
    }
  }

  const activeOrders = data?.data || [];

  return (
    <>
      <section className="mb-8 px-4 pb-8">
        {activeOrders && activeOrders.map((order, index) => (
          <div key={index} className="mb-4">
            <h2 className="text-lg font-semibold mb-2">
              Table {order.table_id}
            </h2>
            <table className="border-collapse w-full">
              <thead>
                <tr>
                  <th className="border border-gray-400 px-4 py-2">Product</th>
                  <th className="border border-gray-400 px-4 py-2">Quantity</th>
                </tr>
              </thead>
              <tbody>
                {order && order.order_items.length > 0 && order.order_items.map((item, itemIndex) => (
                  <tr key={itemIndex}>
                    <td className="border border-gray-400 px-4 py-2">
                      {item.product.name}
                    </td>
                    <td className="border border-gray-400 px-4 py-2">
                      {item.quantity}x
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button
                onClick={() => handleCloseOrder(order.table_id)}
                className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md"
            >
              Close Order
            </button>
          </div>
        ))}
      </section>
    </>
  );
};

export default ActiveOrders;
