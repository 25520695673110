import React, { useState } from "react";
import { toast } from "react-toastify";
import { changePassword } from "../../services/User";

const Settings = () => {
  // State variables for form fields
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();
    if (newPassword == currentPassword) {
      toast.warn("New password cannot be the same as the current password!");
      return;
    }
    if (newPassword != confirmPassword) {
      toast.warn("Passwords are not matching");
      return;
    }
    try {
      const payload = {
        current_password: currentPassword,
        new_password: newPassword,
        password_confirmation: confirmPassword,
      };

      await changePassword(payload);

      // Clear input fields after successful submission

      toast.success("Password changed successfully");
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    }
  };

  return (
    <>
      <div className="form-control mx-4 mt-8">
        <label className="label cursor-pointer">
          <span className=" label-text mr-4 dark:text-neutral-200">
            Dark mode
          </span>
          <input
            type="checkbox"
            className="toggle"
            defaultChecked={localStorage.getItem("darkMode") === "true"}
            onChange={(e) => {
              if (e.target.checked) {
                localStorage.setItem("darkMode", "true");
                document.querySelector("html")?.classList.add("dark");
              } else {
                localStorage.setItem("darkMode", "false");
                document.querySelector("html")?.classList.remove("dark");
              }
            }}
          />
        </label>
      </div>

      <form className="m-4" onSubmit={submitHandler}>
        <h2 className="mb-8">Change password</h2>
        <div className="flex flex-col gap-2">
          <label className="label-text text-neutral-500" htmlFor="">
            Current Password
          </label>
          <input
            id="old"
            type="password"
            placeholder="Enter current password"
            required
            className="px-4 py-2 rounded-lg dark:bg-neutral-800"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          <label className="label-text text-neutral-500" htmlFor="">
            New Password
          </label>
          <input
            id="new"
            type="password"
            placeholder="Enter password"
            required
            className="px-4 py-2 rounded-lg dark:bg-neutral-800"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <label className="label-text text-neutral-500" htmlFor="">
            Confirm Password
          </label>
          <input
            id="confirm"
            type="password"
            placeholder="Confirm password"
            required
            className="px-4 py-2 rounded-lg dark:bg-neutral-800"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <button className="px-4 py-2 mt-4 hover:bg-success transition-colors bg-green-500 rounded-lg text-white">
          Change password
        </button>
      </form>
    </>
  );
};

export default Settings;
