import { Chart as ChartJS, registerables } from "chart.js";
import { Line } from "react-chartjs-2";

const AreaChart = ({ data }) => {
  ChartJS.register(...registerables);
  const chartData = {
    labels: data.map((item) => item.date),
    datasets: [
      {
        label: "Hours Played",
        data: data.map((item) => parseInt(item.hoursPlayed) || 0),
        fill: true,
        backgroundColor: "rgba(173, 216, 230, 0.6)", // Adjust the color as needed
        borderColor: "rgba(0, 128, 128, 0.8)", // Adjust the border color as needed
      },
    ],
  };

  return (
    <div className="chart-container min-w-0 p-4 w-full bg-white dark:bg-neutral-800 rounded-xl shadow-lg">
      <div className="px-2">
        <h2 style={{ textAlign: "left" }}>Time Played By Days</h2>
        <p
          style={{ textAlign: "left" }}
          className="text-neutral-400 dark:text-neutral-500 text-xs font-semibold py-2"
        >
          Last 30 days
        </p>
      </div>

      <Line
        data={chartData}
        options={{
          scales: {
            x: {
              type: "category",
              labels: data.map((item) => item.date),
            },
            y: {
              beginAtZero: true,
            },
          },
          elements: {
            line: {
              tension: 0.4, // Adjust the tension of the line as needed
              borderWidth: 2, // Adjust the border width as needed
              borderCapStyle: "round",
              borderJoinStyle: "round",
              fill: true,
            },
          },
          plugins: {
            title: {
              display: false,
              text: "Last 30 days",
              position: "top",
            },
            legend: {
              display: false,
            },
          },
        }}
      />
    </div>
  );
};

export default AreaChart;
