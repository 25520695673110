import { HamburgerIcon, UserIcon } from "../assets/icons";

const Navbar = ({ className, user, sideBarHandler }: any) => {
  return (
    <header
      className={`fixed z-40 transition-all duration-300 w-full bg-neutral-100 dark:bg-brown-900 py-4 shadow ${className}`}
    >
      <nav className="flex justify-between px-4">
        <div className="flex items-center gap-4">
          <button
            onClick={sideBarHandler}
            className="text-neutral-900 dark:text-white"
          >
            <HamburgerIcon />
          </button>
        </div>
        <div className="flex items-center gap-8">
          <p className="mr-4 text-sm text-neutral-700 uppercase tracking-wider font-medium dark:text-neutral-100 flex items-center gap-4">
            {user.name || ""}
            <UserIcon />
          </p>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
