import { getWithCaching } from "../utilities/caching";
import api from "../utilities/fetching";

export const logout = async (token: string) => {
  return await api.delete("logout");
};

export const changePassword = async (payload: any) => {
  return await api.put("user/password", payload);
};

export const getTotalRevenue = async () => {
  return getWithCaching("total_revenue", () => api.get("revenue"));
};

export const getTotalTime = async (days = 7) => {
  return getWithCaching("total_time", () =>
    api.get(`stopwatch/stats?days=${days}`)
  );
};

export const getTableTime = async (days = 7) => {
  return getWithCaching("table_time", () =>
    api.get(`tables/stats?days=${days}`)
  );
};

export const getDailyTotalTime = async (days = 30) => {
  return getWithCaching("total_daily_time", () =>
    api.get(`stopwatch/stats/total?days=${days}`)
  );
};

export const getUsers = async () => {
  return await api.get("users");
};

export const toggleActive = async (id: number, active: boolean) => {
  return active
    ? await api.patch(`users/${id}/enable`)
    : await api.patch(`users/${id}/disable`);
};

export const registerUser = async (payload: any) => {
    return await api.post("register", payload);
}
