import * as React from "react";
interface Options {
  children: React.ReactNode;
  onChange: any;
  className: string;
  id: string;
  value: string|undefined;
}

const Select = ({ children, id, onChange, className, value=undefined }: Options) => {
  return (
    <div className="block relative w-full">
      <select
        value={value}
        onChange={onChange}
        className={`block appearance-none w-full bg-white dark:bg-neutral-800 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline ${className}`}
        id={id}
      >
        {children}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 dark:text-neutral-400">
        <svg
          className="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>
  );
};

export default Select;
